import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BaseModel } from '../model/base.model';
import { ContratModel } from "../model/contrat.model";
import { SuspensionModel } from "../model/suspension.model";

@Injectable({
  providedIn: 'root',
})
export class SuspensionService extends ApplicationService {
  searchData: any = {};

  constructor(httpClient: HttpClient) {
    super(httpClient, 'suspensions', 'suspension');
  }

  getAll(): Observable<SuspensionModel[]> {
    return this.httpClient.get(environment.serverUrl + '/suspensions', {
      params: new HttpParams().set('size', this.size.toString())
    })
        .pipe(
            map(response => {
              // @ts-ignore
              return response._embedded.suspensions;
            }),
            catchError(this.handleError('getAll', [])),
        );
  }

  /**
   * Add a suspension to a contract.
   * @param suspension Data for the new suspension
   * @returns Observable<BaseModel> indicating the result of the operation
   */
  addToContract(suspension: { id: number,  contratId: number; dateDebut: string; dateFin: string, motifSuspension: string }): Observable<BaseModel> {
    const endpoint = `${environment.serverBaseUrl}suspension`;
    console.log('Adding Suspension to Contract:', JSON.stringify(suspension));

    return this.httpClient.post<BaseModel>(endpoint, suspension)
        .pipe(
            catchError((err) => {
              console.error('Error adding suspension:', err);
              return throwError(err);
            }),
        );
  }

  findById(id: number): Observable<SuspensionModel> {
    return this.httpClient.get<SuspensionModel>(environment.serverUrl + `/contrats/${id}/suspension`)
        .pipe(
            catchError(this.handleError<SuspensionModel>(`findById`)),
        );
  }

  getContrat(id: number): Observable<ContratModel> {
    return this.httpClient.get<ContratModel>(environment.serverUrl + `/suspensions/${id}/contrat`)
        .pipe(
            catchError(this.handleError<ContratModel>(`getContrat`)),
        );
  }

  getByUrl(url: string): Observable<SuspensionModel> {
    return this.httpClient.get<SuspensionModel>(url).pipe(
        catchError(this.handleError<SuspensionModel>(`getOne`)),
    );
  }
}
