import {KeyValueSupport} from '../shared/commons';

export class GlobalObject {
    abbatements: KeyValueSupport[] = [
        {value: '', viewValue: '', disabled: false},
        {value: '20', viewValue: '20%', disabled: false},
        {value: '80', viewValue: '80%', disabled: false},
        {value: '85', viewValue: '85%', disabled: false},
    ];
    dureeDetachement: KeyValueSupport[] = [
        {value: 'Durée illimitée', viewValue: 'Durée illimitée', disabled: false},
        {value: 'Durée 3 + 3 ans', viewValue: 'Durée 3 + 3 ans', disabled: false},
    ];
    typeAvancement: KeyValueSupport[] = [
        {value: 'Avancement à l’ancienneté', viewValue: 'Avancement à l’ancienneté', disabled: false},
        {
            value: 'Avancement promotion exceptionnelle', viewValue: 'Avancement promotion exceptionnelle',
            disabled: false,
        },
        {value: 'Reclassement', viewValue: 'Reclassement', disabled: false},

    ];
    categorieTypeDocument: KeyValueSupport[] = [
        {value: 'Documents identité', viewValue: 'Documents identité', disabled: false},
        {value: 'Contrats', viewValue: 'Contrats', disabled: false},
        {value: 'Documents recrutements', viewValue: 'Documents recrutements', disabled: false},
        {value: 'Avancements/promotions', viewValue: 'Avancements/promotions', disabled: false},
        {value: 'Absences', viewValue: 'Absences', disabled: false},
        {value: 'Rapports incidents', viewValue: 'Rapports incidents', disabled: false},
        {value: 'Divers', viewValue: 'Divers', disabled: false},
    ];
    grilleType: KeyValueSupport[] = [
        {value: 'Base', viewValue: 'Base', disabled: false},
        {value: 'Promotion', viewValue: 'Promotion', disabled: false},
    ];
    typeEvaluations: KeyValueSupport[] = [
        {
            value: 'agent-service',
            viewValue: 'Agents de service et ASEMS',
            disabled: false,
        },
        {
            value: 'administratifs',
            viewValue: 'Administratifs, vie scolaire, infirmières',
            disabled: false,
        },
        {
            value: 'enseignants-principaux',
            viewValue: 'Enseignants et conseillers principaux ',
            disabled: true,
        },
        {
            value: 'surveillants',
            viewValue: 'Surveillants',
            disabled: false,
        },
        {
            value: 'techniciens',
            viewValue: 'Techniciens de laboratoire, techniciens informatiques, ingénieurs',
            disabled: false,
        },
    ];
    avisEvaluateurs: KeyValueSupport[] = [
        {value: 'sansobjet', viewValue: 'Sans objet', disabled: false},
        {value: 'defavorable', viewValue: 'Avis non favorable promotion exceptionnelle', disabled: false},
        {value: 'reserve', viewValue: 'Avis favorable promotion exceptionnelle', disabled: false},
        {value: 'favorable', viewValue: 'Avis très favorable promotion exceptionnelle', disabled: false},
    ];
    statutContrats: KeyValueSupport[] = [
        {value: 'tout', viewValue: 'Tout', disabled: false},
        {value: 'actif', viewValue: 'Actif', disabled: false},
        {value: 'suspendu', viewValue: 'Suspendu', disabled: false},
        {value: 'archive', viewValue: 'Archivé', disabled: false},
        {value: 'radie', viewValue: 'Radié', disabled: false},
    ];
    formats: KeyValueSupport[] = [
        {value: 'excel', viewValue: 'EXCEL', disabled: false},
        {value: 'csv', viewValue: 'CSV', disabled: true},
        {value: 'pdf', viewValue: 'PDF', disabled: true},
    ];
    typeMage: KeyValueSupport[] = [
        {value: 'Tableau Emploi', viewValue: 'Tableau Emploi', disabled: false},
        {value: 'Remplacement ', viewValue: 'Remplacement ', disabled: false},
        {value: 'Mis à disposition', viewValue: 'Mis à disposition', disabled: false},
        {value: 'Vacataire', viewValue: 'Vacataire', disabled: false},
        {value: 'Rompu de temps partiel', viewValue: 'Rompu de temps partiel', disabled: false},
        {value: 'Recrutement résident différé', viewValue: 'Recrutement résident différé', disabled: false},
    ];
    criteriaList: KeyValueSupport[] = [
        {value: '', viewValue: 'Sélectionner', disabled: false},
        {value: 'etablissementId', viewValue: 'Établissement', disabled: false},
        {value: 'genreId', viewValue: 'Genre', disabled: false},
        {value: 'situationFamilialeId', viewValue: 'Situation Familiale', disabled: false},
        {value: 'nationaliteId', viewValue: 'Nationalité', disabled: false},
        {value: 'niveauEtudeId', viewValue: 'Niveau d’étude', disabled: false},
        {value: 'posteType', viewValue: 'Type MAGE', disabled: false},
        {value: 'cfeActivated', viewValue: 'CFE', disabled: false},
        {value: 'cnssActivated', viewValue: 'CNSS', disabled: false},
        {value: 'cimrActivated', viewValue: 'CIMR', disabled: false},
        {value: 'totalEnfantsAcharge', viewValue: 'Enfants à charge', disabled: false},
        {value: 'contratEncadrement', viewValue: 'Encadrement', disabled: false},
        {value: 'contratDisponibilite', viewValue: 'Titulaire en disponibilité', disabled: false},
        {value: 'typeStatutId', viewValue: 'Statut', disabled: false},
        {value: 'typeFonctionId', viewValue: 'Fonction', disabled: false},
        {value: 'typeCorpsId', viewValue: 'Corps', disabled: false},
        {value: 'typeRegimeId', viewValue: 'Régime', disabled: false},
        {value: 'contratStatus', viewValue: 'Statut contrat', disabled: false},
        {value: 'typeContratId', viewValue: 'Type contrat', disabled: true},
        {value: 'contratDateEntreeErc', viewValue: 'Date d’entrée', disabled: false},
        {value: 'contratDateSortieErc', viewValue: 'Date de sortie', disabled: false},
        {value: 'contratDateArchivage', viewValue: 'Date d’archivage', disabled: false},
        {value: 'contratDatePromotion', viewValue: 'Date de promotion', disabled: false},
        {value: 'dateNaissance', viewValue: 'Date de naissance', disabled: false},
        {value: 'contratDateCdd', viewValue: 'Date de CDD', disabled: false},
        {value: 'contratDateCdi', viewValue: 'Date de CDI', disabled: false},
        {value: 'maxContratDateEntreeErc', viewValue: 'Date d’entrée jusqu’au', disabled: false},
    ];
    orderedFields = {
        personneFields: ['personneId', 'contratId', 'contratMatriculeAgence', 'contratNumen', 'contratNumeroSom',
            'genre', 'nomUsuel', 'nom', 'prenom', 'dateNaissance', 'lieuNaissance', 'nationalite', 'niveauEtude',
            'situationFamiliale', 'totalEnfantsAcharge', 'email', 'emailDeux', 'emailAefe', 'numeroCarteNationale',
            'dateValiditeCarteNationale', 'cnssActivated', 'numeroCnss', 'cnopsActivated', 'numeroCnops',
            'cfeActivated', 'numeroCfe', 'cimrActivated', 'numeroCimr', 'mgenActivated', 'numeroMgen',
            'mgpapActivated', 'numeroMgpap'],
        contactUrgenceFields: ['nomContactUrgence', 'prenomContactUrgence', 'emailContactUrgence', 'mobileContactUrgence',
            'adresseContactUrgence', 'codePostalContactUrgence', 'villeContactUrgence'],
        contratFields: ['etablissement', 'contratDateEntreeErc', 'contratDateCdd', 'contratDateFinCdd',
            'contratDateCdi', 'contratDateSortieErc', 'contratDateDebutDetachement', 'contratDateFinDetachement',
            'contratStatus', 'typeRegime', 'typeStatut', 'contratDisponibilite', 'contratMinistere', 'contratAcademie',
            'contratNumeroPoste', 'typeCorps', 'categorieMage', 'categorieRit', 'typeContrat', 'contratFonction',
            'contratDiscipline', 'posteQuotiteHebdo', 'contratReliquat', 'contratDatePromotion', 'contratDateAvancement',
            'dateProchainePromotion', 'prochainePromotion', 'dateDerniereEvaluation', 'dateProchainePrimeAnciennete',
            'prochainePrimeAnciennete'],
        grilleFields: ['grille', 'contratEchelon', 'grilleIndice', 'contratGrade', 'contratIndiceBrut',
            'contratIndiceMajore', 'contratChevron', 'contratIsvl', 'contratIss', 'contratAbbat'],
        adresseMarocFields: ['adresseMaroc', 'villeMaroc', 'codePostalMaroc', 'gsmMaroc', 'telephoneMaroc'],
        adresseHorsMarocFields: ['adresseHorsMaroc', 'villeHorsMaroc', 'codePostalHorsMaroc', 'paysHorsMaroc',
            'gsmHorsMaroc', 'telephoneHorsMaroc'],
        autreFields: ['Diplome', 'QuotiteHebdomadaire', 'Avancement', 'Enfant', 'Conjoint', 'AccidentTravail',
            'VisiteMedicale', 'Cte', 'CompteBancaire', 'Adresse', 'Evaluation', 'Formation'],
    };
    fields = {
        personneFields: {
            personneId: [false]
            , nom: [false]
            , nomUsuel: [false]
            , prenom: [false]
            , email: [false]
            , emailDeux: [false]
            , emailAefe: [false]
            , dateNaissance: [false]
            , lieuNaissance: [false]
            , numeroCarteNationale: [false]
            , dateValiditeCarteNationale: [false]
            , numeroCnss: [false]
            , numeroCfe: [false]
            , numeroCimr: [false]
            , cnssActivated: [false]
            , cfeActivated: [false]
            , cimrActivated: [false]
            , numeroCnops: [false]
            , numeroMgen: [false]
            , numeroMgpap: [false]
            , cnopsActivated: [false]
            , mgenActivated: [false]
            , mgpapActivated: [false]
            , genre: [false]
            , situationFamiliale: [false]
            , nationalite: [false]
            , niveauEtude: [false]
            , totalEnfantsAcharge: [false]
            , contratId: [false]
            , contratNumeroSom: [false]
            , contratMatriculeAgence: [false]
            , contratNumen: [false],

        },
        contactUrgenceFields: {
            nomContactUrgence: [false]
            , prenomContactUrgence: [false]
            , emailContactUrgence: [false]
            , mobileContactUrgence: [false]
            , adresseContactUrgence: [false]
            , codePostalContactUrgence: [false]
            , villeContactUrgence: [false],
        },
        contratFields: {
            etablissement: [false],
            contratDateEntreeErc: [false],
            contratDateCdd: [false],
            contratDateFinCdd: [false],
            contratDateCdi: [false],
            contratDateSortieErc: [false],
            contratDateDebutDetachement: [false],
            contratDateFinDetachement: [false],
            contratStatus: [false],
            contratDatePromotion: [false],
            contratDateAvancement: [false],
            typeRegime: [false],
            typeStatut: [false],
            contratMinistere: [false],
            contratAcademie: [false],
            contratNumeroPoste: [false],
            typeCorps: [false],
            categorieMage: [false],
            categorieRit: [false],
            typeContrat: [false],
            contratFonction: [false],
            contratDiscipline: [false],
            posteQuotiteHebdo: [false],
            contratReliquat: [false],
            dateProchainePrimeAnciennete: [false],
            prochainePrimeAnciennete: [false],
            dateProchainePromotion: [false],
            dateDerniereEvaluation: [false],
            prochainePromotion: [false],
            contratDisponibilite: [false],
        },
        grilleFields: {
            grille: [false],
            contratEchelon: [false],
            grilleIndice: [false],
            contratGrade: [false],
            contratIndiceBrut: [false],
            contratIndiceMajore: [false],
            contratChevron: [false],
            contratIsvl: [false],
            contratIss: [false],
            contratAbbat: [false],
        },
        adresseMarocFields: {
            adresseMaroc: [false]
            , villeMaroc: [false]
            , codePostalMaroc: [false]
            , gsmMaroc: [false]
            , telephoneMaroc: [false],
        },
        adresseHorsMarocFields: {
            adresseHorsMaroc: [false]
            , villeHorsMaroc: [false]
            , codePostalHorsMaroc: [false]
            , paysHorsMaroc: [false]
            , gsmHorsMaroc: [false]
            , telephoneHorsMaroc: [false],
        },
        autreFields: {
            Diplome: [false],
            QuotiteHebdomadaire: [false],
            Avancement: [false],
            Enfant: [false],
            Conjoint: [false],
            AccidentTravail: [false],
            VisiteMedicale: [false],
            Cte: [false],
            CompteBancaire: [false],
            Adresse: [false],
            Evaluation: [false],
            Formation: [false],
        },
    };
    fieldsLabels = {
        personneId: 'Identifiant personnel'
        , nom: 'Nom'
        , nomUsuel: 'Nom usuel'
        , prenom: 'Prénom'
        , prenomDeux: 'Deuxième prénom'
        , prenomTrois: 'Troisième prénom'
        , email: 'E-mail'
        , emailDeux: 'E-mail professionel'
        , mobile: 'Mobile'
        , mobileDeux: 'Deuxième mobile'
        , dateNaissance: 'Date de naissance'
        , lieuNaissance: 'Lieu de naissance'
        , numeroCarteNationale: 'Numéro de la carte Nationale'
        , dateValiditeCarteNationale: 'Date de validité de carte nationale'
        , numeroCnss: 'Numéro de CNSS'
        , numeroCfe: 'Numéro de CFE'
        , numeroCimr: 'Numéro de CIMR'
        , cnssActivated: 'CNSS activé'
        , cfeActivated: 'CFE activé'
        , cimrActivated: 'CIMR activé'
        , numeroCnops: 'Numéro de CNOPS'
        , numeroMgen: 'Numéro DE MGEN'
        , numeroMgpap: 'Numéro de MGPAP'
        , cnopsActivated: 'CNOPS activé'
        , mgenActivated: 'MGEN activé'
        , mgpapActivated: 'MGPAP activé'
        , emailAefe: 'E-mail AEFE'
        , genre: 'Genre'
        , situationFamiliale: 'Situation familiale'
        , nationalite: 'Nationalité'
        , niveauEtude: 'Niveau d\'étude'
        , totalEnfantsAcharge: 'Total enfants à charge'
        , nomContactUrgence: 'Nom'
        , prenomContactUrgence: 'Prénom'
        , emailContactUrgence: 'E-mail'
        , mobileContactUrgence: 'Mobile'
        , adresseContactUrgence: 'Adresse'
        , codePostalContactUrgence: 'Code postal'
        , villeContactUrgence: 'Ville'
        , contratId: 'Identifiant de contrat'
        , contratClasseEchelle: 'Classe ou échelle'
        , contratIndiceBrut: 'Indice brut'
        , contratIndiceMajore: 'Indice majoré'
        , contratChevron: 'Chevron'
        , contratGrade: 'Grade'
        , contratEchelon: 'Echelon'
        , contratIsvl: 'ISVL'
        , contratNumeroSom: 'Numéro de Som'
        , contratIss: 'ISS'
        , contratCexpat: 'Cexpat'
        , contratReliquat: 'Reliquat'
        , contratAbbat: 'Abbatement'
        , contratNumeroPoste: 'Numéro de poste'
        , contratEdt: 'EDT'
        , contratGroupech: 'Groupech'
        , contratRrd: 'RRD'
        , contratRadier: 'Radier'
        , contratEncadrement: 'Encadrement'
        , contratDisponibilite: 'Titulaire en disponibilité'
        , contratAcademie: 'Académie'
        , contratMatriculeAgence: 'Matricule d\'agence'
        , contratMinistere: 'Ministère'
        , contratNoteAdm: 'Note administrative'
        , contratNotePre: 'Note Pre'
        , contratAnneeNote: 'Année de note'
        , contratObservations: 'Observations'
        , contratAppreciation: 'Appreciation'
        , contratPeriodeDetachement: 'Période de détachement '
        , contratDateDebutDetachement: 'Date début détachement'
        , contratDateFinDetachement: 'Date fin de détachement '
        , contratDateDebutSuspension: 'Date début de suspension'
        , contratDateFinSuspension: 'Date fin de suspension'
        , contratDateArchivage: 'Date d\'archivage'
        , contratDateMaroc: 'Date Maroc'
        , contratDateContrat: 'Date du contrat'
        , contratDatePromotion: 'Date de la promotion en vigueur'
        , contratDateAvancement: 'Date de la promotion accélérée'
        , contratDateAppreciation: 'Date d\'appréciation'
        , contratDateCdd: 'Date de CDD'
        , contratDateFinCdd: 'Date fin de CDD'
        , contratDateCdi: 'Date de CDI'
        , contratDateEntreeErc: 'Date d\'entrée ERC'
        , contratDateSortieErc: 'Date de sortie ERC'
        , typeFonctionId: 'Identifiant de fonction'
        , contratFonction: 'Fonction'
        , contratDiscipline: 'Discipline'
        , categorieMage: 'Catégorie MAGE'
        , categorieRit: 'Catégorie RIT'
        , contratStatus: 'Status'
        , contratNumen: 'Numen'
        , etablissement: 'Etablissement'
        , typeCorps: 'Corps'
        , typeStatut: 'Statut'
        , typeRegime: 'Régime'
        , typeContrat: 'Type de contrat'
        , dateProchainePrimeAnciennete: 'Date Prochaine Prime Anciennete'
        , prochainePrimeAnciennete: 'Prochaine Prime Anciennete'
        , dateProchainePromotion: 'Date Prochaine Promotion'
        , dateDerniereEvaluation: 'Date Dernière Evaluation'
        , prochainePromotion: 'Prochaine Promotion'
        , poste: 'Poste'
        , posteNationalite: 'Nationalitée'
        , posteFonction: 'Fonction'
        , posteDiscipline: 'Discipline'
        , posteContrat: 'Contrat'
        , posteTemps: 'Temps'
        , posteQuotite: 'Quotitée'
        , posteQuotiteHebdo: 'Quotité hebdomadaire'
        , posteNiveauEtude: 'Niveau d\'étude'
        , posteDateEmbauche: 'Date d\'embauche'
        , posteDateNaissance: 'Date de naissance'
        , posteEtp: 'ETP'
        , posteType: 'Type'
        , grille: 'Grille'
        , grilleEchelon: 'Echelon'
        , grilleDuree: 'Durée'
        , grilleIndice: 'Indice'
        , grilleRit: 'Rit'
        , adresseMaroc: 'Adresse'
        , villeMaroc: 'Ville'
        , codePostalMaroc: 'Code postal'
        , gsmMaroc: 'GSM'
        , telephoneMaroc: 'Téléphone'
        , adresseHorsMaroc: 'Adresse'
        , villeHorsMaroc: 'Ville'
        , codePostalHorsMaroc: 'Code postal'
        , paysHorsMaroc: 'Pays'
        , gsmHorsMaroc: 'GSM'
        , telephoneHorsMaroc: 'Téléphone'
        , Diplome: 'Diplôme'
        , QuotiteHebdomadaire: 'Quotité Hebdomadaire'
        , Avancement: 'Avancement'
        , Enfant: 'Enfant'
        , Conjoint: 'Conjoint'
        , AccidentTravail: 'Accident de Travail'
        , VisiteMedicale: 'Visite Médicale'
        , Cte: 'Cte'
        , CompteBancaire: 'Compte Bancaire'
        , Adresse: 'Adresse'
        , Evaluation: 'Evaluation'
        , Formation: 'Formation',
    };
    anneeScolaire: KeyValueSupport[] = [
        {value: '2024/2025', viewValue: '2024/2025', disabled: false},
        {value: '2023/2024', viewValue: '2023/2024', disabled: false},
        {value: '2022/2023', viewValue: '2022/2023', disabled: false},
        {value: '2021/2022', viewValue: '2021/2022  ', disabled: false},
        {value: '2020/2021', viewValue: '2020/2021', disabled: false},
        {value: '2019/2020', viewValue: '2019/2020', disabled: false},
    ];
    anneeScolaireTotal: KeyValueSupport[] = [
        {value: '2024/2025', viewValue: '2024/2025', disabled: false},
        {value: '2023/2024', viewValue: '2023/2024', disabled: false},
        {value: '2022/2023', viewValue: '2022/2023', disabled: false},
        {value: '2021/2022', viewValue: '2021/2022', disabled: false},
        {value: '2020/2021', viewValue: '2020/2021', disabled: false},
        {value: '2019/2020', viewValue: '2019/2020', disabled: false},
        {value: 'TOUT', viewValue: 'Tout', disabled: false},
    ];
    public: KeyValueSupport[] = [
        {value: 'Enseignants 1er Degré', viewValue: 'Enseignants 1er Degré', disabled: false},
        {value: 'Enseignants 2nd Degré', viewValue: 'Enseignants 2nd Degré', disabled: false},
        {value: 'Enseignants des deux degrés', viewValue: 'Enseignants des deux degrés', disabled: false},
        {value: 'Formateurs AEFE', viewValue: 'Formateurs AEFE', disabled: false},
        {value: 'Personnels administratifs', viewValue: 'Personnels administratifs', disabled: false},
        {value: 'Personnels de direction', viewValue: 'Personnels de direction', disabled: false},
        {value: 'Professeurs documentalistes', viewValue: 'Professeurs documentalistes', disabled: false},
        {value: 'Personnels d\'orientation', viewValue: 'Personnels d’orientation', disabled: false},
        {value: 'Personnels de service', viewValue: 'Personnels de service', disabled: false},
        {value: 'Tous les personnels', viewValue: 'Tous les personnels', disabled: false},
    ];
    legacyEvaluationAdministratifs = [
        {
            key: 'competences_professionnelles_technicite',
            title: 'Compétences professionnelles et technicité',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'maitrise_technique',
                    title: 'Maîtrise du domaine d’activité et connaissance de l’environnement',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'volonte',
                    title: 'Capacité d’organisation, de formalisation, d’anticipation, d’innovation, d’analyse et de synthèse',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite',
                    title: 'Qualité d’expression écrite et orale',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'contribution_activite_service',
            title: 'Contribution à l’activité du service',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'sens_du_service_public',
                    title: 'Sens du service public et conscience professionnelle (notamment : loyauté, respect du devoir de réserve, ponctualité, assiduité, participation aux charges collectives)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur et efficacité (fiabilité et qualité du travail effectué, respect des délais, des normes et des procédures, sens de l’organisation, sens de la méthode, attention portée à la qualité du service rendu)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'dynamisme_capacite_reagir',
                    title: 'Dynamisme et capacité à réagir, investissement et initiatives personnelles, sens des responsabilités',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'autonomie_discernement',
                    title: 'Autonomie, discernement et sens des initiatives dans l’exercice de ses attributions',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_adaptation',
                    title: 'Capacité d’adaptation et de travail en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitudes_relationnelles',
                    title: 'Aptitudes relationnelles (avec le public et dans l’environnement professionnel), notamment maitrise de soi',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'aptitude_management_projets',
            title: 'Le cas échéant, aptitude au management et/ou à la conduite de projets (si poste d’encadrement)',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'capacite_former',
                    title: 'Capacité à former, identifier, mobiliser les compétences individuelles et collectives',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_organisation',
                    title: 'Capacité d’organisation et de pilotage, aptitude à prendre des décisions et à les faire appliquer',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitude_prevenir_conflits',
                    title: 'Aptitude à prévenir, arbitrer et gérer les conflits, à dialoguer et négocier',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'evaluation_par_rapport_aux_objectifs_assignes',
                    title: 'Evaluation par rapport aux objectifs assignés',
                    type: 'textarea',
                },
                {
                    key: 'objectifs_principaux_pour_l_annee_a_venir',
                    title: 'Objectifs principaux pour l’année à venir',
                    type: 'textarea',
                },
            ],
        },
    ];
    legacyEvaluationSurveillants = [
        {
            key: 'savoir_etre',
            title: 'Savoir-être',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'assiduite_ponctualite',
                    title: 'Assiduité / Ponctualité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur / Efficacité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'investissement',
                    title: 'Investissement',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'prise_initiative',
                    title: 'Prise d’initiative',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'savoir_faire',
            title: 'Savoir-faire',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'taches_administratives',
                    title: 'Tâches administratives',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'suivi_eleves',
                    title: 'Suivi des élèves',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'surveillance_autorite_posture',
                    title: 'Surveillance, autorité et posture',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'respect_consignes',
                    title: 'Respect des consignes',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'autonomie_discernement',
                    title: 'Autonomie, discernement et sens des initiatives dans l’exercice de ses attributions',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_adaptation',
                    title: 'Capacité d’adaptation et de travail en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitudes_relationnelles',
                    title: 'Aptitudes relationnelles (avec le public et dans l’environnement professionnel), notamment maitrise de soi',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'evaluation_par_rapport_aux_objectifs_assignes',
                    title: 'Evaluation par rapport aux objectifs assignés',
                    type: 'textarea',
                },
                {
                    key: 'objectifs_principaux_pour_l_annee_a_venir',
                    title: 'Objectifs principaux pour l’année à venir',
                    type: 'textarea',
                },
            ],
        },
    ];
    legacyEvaluationAgentService = [
        {
            key: 'competences_professionnelles_technicite',
            title: 'Compétences professionnelles et technicité',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'maitrise_technique',
                    title: 'Maîtrise technique',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'volonte',
                    title: 'Volonté de s’informer et de se former',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite',
                    title: 'Capacité d’adaptation',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'contribution_activite_service',
            title: 'Contribution à l’activité du service',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur et efficacité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'sens_responsabilite',
                    title: 'Dynamisme et capacité à réagir, sens des responsabilités',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_travail',
                    title: 'Capacité de travail',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'expression_orale',
                    title: 'Capacités d’expression orale (en français)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'conscience_professionnelle',
                    title: 'Conscience professionnelle (loyauté, respect, ponctualité, assiduité)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'travail_equipe',
                    title: 'Capacité à travailler en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'evaluation_par_rapport_aux_objectifs_assignes',
                    title: 'Evaluation par rapport aux objectifs assignés',
                    type: 'textarea',
                },
                {
                    key: 'objectifs_principaux_pour_l_annee_a_venir',
                    title: 'Objectifs principaux pour l’année à venir',
                    type: 'textarea',
                },
            ],
        },
    ];
    legacyEvaluationEnseignantCdd = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves ' +
                                'dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement ' +
                                'efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue:
                                'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'demarche_developpement_professionnel',
                    title: 'Démarche de développement professionnel envisagée –Besoins de formation identifiés',
                    type: 'textarea',
                },
            ],
        },
    ];
    legacyEvaluationEnseignantCdi = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves ' +
                                'dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement ' +
                                'efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer ' +
                                'un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue: 'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'demarche_developpement_professionnel',
                    title: 'Démarche de développement professionnel envisagée –Besoins de formation identifiés',
                    type: 'textarea',
                },
            ],
        },
    ];
    legacyEvaluationEnseignantVacataire = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves ' +
                                'dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer ' +
                                'un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue: 'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    type: 'textarea',
                },
                {
                    key: 'demarche_developpement_professionnel',
                    title: 'Démarche de développement professionnel envisagée –Besoins de formation identifiés',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationAdministratifs = [
        {
            key: 'competences_professionnelles_technicite',
            title: 'Compétences professionnelles et technicité',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'maitrise_technique',
                    title: 'Maîtrise du domaine d’activité et connaissance de l’environnement',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'volonte',
                    title: 'Capacité d’organisation, de formalisation, d’anticipation, d’innovation, d’analyse et de synthèse',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite',
                    title: 'Qualité d’expression écrite et orale',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'contribution_activite_service',
            title: 'Contribution à l’activité du service',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'sens_du_service_public',
                    title: 'Sens du service public et conscience professionnelle (notamment : loyauté, respect du devoir de réserve, ponctualité, assiduité, participation aux charges collectives)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur et efficacité (fiabilité et qualité du travail effectué, respect des délais, des normes et des procédures, sens de l’organisation, sens de la méthode, attention portée à la qualité du service rendu)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'dynamisme_capacite_reagir',
                    title: 'Dynamisme et capacité à réagir, investissement et initiatives personnelles, sens des responsabilités',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'autonomie_discernement',
                    title: 'Autonomie, discernement et sens des initiatives dans l’exercice de ses attributions',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_adaptation',
                    title: 'Capacité d’adaptation et de travail en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitudes_relationnelles',
                    title: 'Aptitudes relationnelles (avec le public et dans l’environnement professionnel), notamment maitrise de soi',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'aptitude_management_projets',
            title: 'Le cas échéant, aptitude au management et/ou à la conduite de projets (si poste d’encadrement)',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'capacite_former',
                    title: 'Capacité à former, identifier, mobiliser les compétences individuelles et collectives',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_organisation',
                    title: 'Capacité d’organisation et de pilotage, aptitude à prendre des décisions et à les faire appliquer',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitude_prevenir_conflits',
                    title: 'Aptitude à prévenir, arbitrer et gérer les conflits, à dialoguer et négocier',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationSurveillants = [
        {
            key: 'savoir_etre',
            title: 'Savoir-être',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'assiduite_ponctualite',
                    title: 'Assiduité / Ponctualité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur / Efficacité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'investissement',
                    title: 'Investissement',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'prise_initiative',
                    title: 'Prise d’initiative',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'savoir_faire',
            title: 'Savoir-faire',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'taches_administratives',
                    title: 'Tâches administratives',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'suivi_eleves',
                    title: 'Suivi des élèves',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'surveillance_autorite_posture',
                    title: 'Surveillance, autorité et posture',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'respect_consignes',
                    title: 'Respect des consignes',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'autonomie_discernement',
                    title: 'Autonomie, discernement et sens des initiatives dans l’exercice de ses attributions',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_adaptation',
                    title: 'Capacité d’adaptation et de travail en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'aptitudes_relationnelles',
                    title: 'Aptitudes relationnelles (avec le public et dans l’environnement professionnel), notamment maitrise de soi',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationAgentService = [
        {
            key: 'competences_professionnelles_technicite',
            title: 'Compétences professionnelles et technicité',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'maitrise_technique',
                    title: 'Maîtrise technique',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'volonte',
                    title: 'Volonté de s’informer et de se former',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite',
                    title: 'Capacité d’adaptation',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'contribution_activite_service',
            title: 'Contribution à l’activité du service',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'rigueur_efficacite',
                    title: 'Rigueur et efficacité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'sens_responsabilite',
                    title: 'Dynamisme et capacité à réagir, sens des responsabilités',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'capacite_travail',
                    title: 'Capacité de travail',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },

            ],
        },
        {
            key: 'qualites_personnelles_relationnelles',
            title: 'Qualités personnelles et relationnelles',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'expression_orale',
                    title: 'Capacités d’expression orale (en français)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'conscience_professionnelle',
                    title: 'Conscience professionnelle (loyauté, respect, ponctualité, assiduité)',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
                {
                    key: 'travail_equipe',
                    title: 'Capacité à travailler en équipe',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'TRES SATISFAISANT'},
                        {value: 2, viewValue: 'SATISFAISANT'},
                        {value: 3, viewValue: 'MOYEN'},
                        {value: 4, viewValue: 'INSUFFISANT'},
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationEnseignantCdd = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves ' +
                                'dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer ' +
                                'un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue: 'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationEnseignantCdi = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue: 'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
    evaluationEnseignantVacataire = [
        {
            key: 'heading',
            title: '',
            group: [
                {
                    key: 'diplomes_dates_obtention',
                    title: 'Diplômes et dates d’obtention',
                    type: 'textarea',
                },
                {
                    key: 'stages',
                    title: 'Trois derniers stages effectués (année, durée et intitulé)',
                    type: 'textarea',
                },
            ],
        },
        {
            key: 'competences_professionnelles_technicite',
            title: '',
            header: ['TRES SATISFAISANT', 'SATISFAISANT', 'MOYEN', 'INSUFFISANT'],
            group: [
                {
                    key: 'ponctualite_assiduite',
                    title: 'Ponctualité Assiduité',
                    type: 'radio',
                    items: [
                        {value: 1, viewValue: 'Fait preuve d’une ponctualité sans faille'},
                        {value: 2, viewValue: 'Fait preuve d’une ponctualité aux défauts anecdotiques'},
                        {value: 3, viewValue: 'Montre des défauts occasionnels de ponctualité'},
                        {value: 4, viewValue: 'Doit améliorer sa ponctualité / limiter ses absences à celles de droit'},
                    ],
                },
                {
                    key: 'autorite_posture',
                    title: 'Autorité et posture',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Adopte un mode de fonctionnement du groupe favorisant la socialisation des élèves ' +
                                'dans une relation de confiance',
                        },
                        {
                            value: 2,
                            viewValue: 'Maintient un climat propice aux apprentissages et un mode de fonctionnement efficace',
                        },
                        {value: 3, viewValue: 'Obtient une tenue de classe généralement convenable'},
                        {
                            value: 4,
                            viewValue: 'Doit réfléchir à un mode de fonctionnement plus efficace et pertinent pour assurer ' +
                                'un climat propice aux apprentissages',
                        },
                    ],
                },
                {
                    key: 'sens_initiative',
                    title: 'Sens de l’initiative',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Intervient et collabore spontanément en toute occasion dans le cadre collectif défini',
                        },
                        {value: 2, viewValue: 'Intervient et collabore régulièrement dans le cadre collectif défini'},
                        {value: 3, viewValue: 'Répond volontiers aux sollicitations dans le cadre collectif défini'},
                        {
                            value: 4,
                            viewValue: 'Doit davantage agir de sa propre initiative / doit tenir compte du cadre collectif défini',
                        },
                    ],
                },
                {
                    key: 'rayonnement_etablissement',
                    title: 'Participation à la vie et au rayonnement de l’établissement ',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Porte des projets transversaux ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 2,
                            viewValue: 'Coopère activement à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 3,
                            viewValue: 'Contribue à des projets ou des actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit s’investir dans des projets ou actions ayant un impact sur l’établissement et/ou participant au rayonnement et à l’ouverture de l’établissement sur son environnement',
                        },
                    ],
                },
                {
                    key: 'relation_eleves',
                    title: 'Relation avec les élèves',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Agit en éducateur responsable et accorde une écoute et une attention positives et appropriées à tous ses élèves',
                        },
                        {
                            value: 2,
                            viewValue: 'Agit en éducateur responsable et accorde une grande attention à tous ses élèves',
                        },
                        {value: 3, viewValue: 'Agit en éducateur responsable'},
                        {
                            value: 4,
                            viewValue: 'Doit accorder à tous les élèves l’attention et l’accompagnement appropriés dans un climat favorable',
                        },
                    ],
                },
                {
                    key: 'relation_parents',
                    title: 'Relation avec les parents',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Entretient une relation proactive de confiance avec les parents d’élèves',
                        },
                        {value: 2, viewValue: 'Entretient un dialogue constructif avec les parents d’élèves'},
                        {value: 3, viewValue: 'Echange et communique régulièrement avec les parents d’élèves'},
                        {
                            value: 4,
                            viewValue: 'Doit œuvrer à la construction d’une relation de confiance avec les parents',
                        },
                    ],
                },
                {
                    key: 'relation_personnels',
                    title: 'Relation avec les autres personnels',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Fait preuve d’une attitude constructive, respectueuse et avenante dans des relations réciproques avec tous les autres personnels',
                        },
                        {
                            value: 2,
                            viewValue: 'Entretient de bonnes relations et collabore avec l’ensemble des personnels',
                        },
                        {
                            value: 3,
                            viewValue: 'Entretient de bonnes relations avec bon nombre de ses collègues, collabore ponctuellement',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit davantage inscrire ses interventions dans un cadre collectif, au service de la complémentarité et de la continuité pédagogique et éducative',
                        },
                    ],
                },
                {
                    key: 'connaissance_organisation_etablissement',
                    title: 'Connaissance de l’organisation de l’établissement et du système éducatif français au Maroc',
                    type: 'radio',
                    items: [
                        {
                            value: 1,
                            viewValue: 'Coopère activement avec tous les services de l’établissement ainsi qu’avec les autres écoles et entités du réseau. Il en connait les missions',
                        },
                        {
                            value: 2,
                            viewValue: 'Inscrit ses interventions en complémentarité avec les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 3,
                            viewValue: 'Connait les autres services de l’établissement, écoles et entités du réseau',
                        },
                        {
                            value: 4,
                            viewValue: 'Doit prendre connaissance des autres services de l’établissement, écoles et entités du réseau',
                        },
                    ],
                },
            ],
        },
        {
            key: 'autre',
            title: 'Autres',
            group: [
                {
                    key: 'appreciation_generale',
                    title: 'Appréciation générale du supérieur hiérarchique',
                    subTitle: '(L’appréciation intégrera les éventuels objectifs assignés pour l’année à venir ainsi que les besoins en formation ou en développement professionnel)',
                    type: 'textarea',
                },
            ],
        },
    ];
}
