import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContratService} from '../../../../lyautey/services/contrat.service';
import {ContratModel} from '../../../../lyautey/model/contrat.model';
import {EtablissementService} from '../../../../lyautey/services/etablissement.service';
import {EtablissementModel} from '../../../../lyautey/model/etablissement.model';
import {StatutModel} from '../../../../lyautey/model/statut.model';
import {StatutService} from '../../../../lyautey/services/statut.service';
import {CorpsModel} from '../../../../lyautey/model/corps.model';
import {CorpsService} from '../../../../lyautey/services/corps.service';
import {TypeContratService} from '../../../../lyautey/services/type-contrat.service';
import {MageService} from '../../../../lyautey/services/mage.service';
import {RitService} from '../../../../lyautey/services/rit.service';
import {RitModel} from '../../../../lyautey/model/rit.model';
import {MageModel} from '../../../../lyautey/model/mage.model';
import {PosteService} from '../../../../lyautey/services/poste.service';
import {PosteModel} from '../../../../lyautey/model/poste.model';
import {RegimeService} from '../../../../lyautey/services/regime.service';
import {RegimeModel} from '../../../../lyautey/model/regime.model';
import {GrilleService} from '../../../../lyautey/services/grille.service';
import {GrilleCategorieModel} from '../../../../lyautey/model/grilleCategorie.model';
import {GrilleTypeModel} from '../../../../lyautey/model/grilleType.model';
import {GrilleNiveauModel} from '../../../../lyautey/model/grilleNiveau.model';
import {GrilleFonctionModel} from '../../../../lyautey/model/grilleFonction.model';
import Swal from 'sweetalert2';
import {CustomDatepickerI18n, I18n} from '../../../../lyautey/shared/CustomDatepickerI18n';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {DatePipe} from '@angular/common';
import {NgbDateMomentParserFormatter} from '../../../../lyautey/shared/NgbDateMomentParserFormatter';
import * as moment from 'moment';
import {KeyValueSupport} from '../../../../lyautey/shared/commons';
import {catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {PersonnelService} from '../../../../lyautey/services/personnel.service';
import {GrilleModel} from '../../../../lyautey/model/grille.model';
import {TypeService} from '../../../../lyautey/services/type.service';
import {FonctionModel} from '../../../../lyautey/model/fonction.model';
import {FonctionService} from '../../../../lyautey/services/fonction.service';
import {PersonneModel} from '../../../../lyautey/model/personnel.model';
import {TypeContratModel} from '../../../../lyautey/model/typeContrat.model';
import {TypeModel} from '../../../../lyautey/model/type.model';
import {VacationCategorieHoraireModel} from '../../../../lyautey/model/vacationCategorieHoraire.model';
import {VacationCategorieHoraireService} from '../../../../lyautey/services/vacation-categorie-horaire.service';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {environment} from '../../../../../environments/environment';
import {DbDate} from '../../../../lyautey/shared/dbDate';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';
import {RedirectPage} from '../../../../lyautey/shared/redirectPage';
import {AvancementService} from '../../../../lyautey/services/avancement.service';
import {PromotionModel} from "../../../../lyautey/model/promotion.model";

@Component({
    selector: 'ngx-contrat',
    templateUrl: './contrat.component.html',
    styleUrls: ['./contrat.component.scss'],
    providers: [
        I18n,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        DatePipe,
        {
            provide: NgbDateMomentParserFormatter,
            useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
        },
    ],
})
export class ContratComponent implements OnInit, CanComponentDeactivate, AfterViewInit{

    employeeId: number;
    editForm: FormGroup;
    submitted: boolean = false;
    etablissements: Observable<EtablissementModel[]>;
    vacationCategorieHoraires: Observable<VacationCategorieHoraireModel[]>;
    statuts: Observable<StatutModel[]>;
    corps: Observable<CorpsModel[]>;
    typeContrats: Observable<TypeContratModel[]>;
    fonctions: Observable<FonctionModel[]>;
    rits: Observable<RitModel[]>;
    mages: Observable<MageModel[]>;
    postes: Observable<PosteModel[]>;
    regimes: Observable<RegimeModel[]>[] = [];
    types: Observable<TypeModel[]>[] = [];
    grilleRits: Observable<string[]>[] = [];
    grilleTypes: Observable<GrilleTypeModel[]>[] = [];
    grilleCategories: Observable<GrilleCategorieModel[]>[] = [];
    grilleNiveaus: Observable<GrilleNiveauModel[]>[] = [];
    grilleFonctions: Observable<GrilleFonctionModel[]>[] = [];
    grilleDescriptions: Observable<string[]>[] = [];
    grilleEchelons: Observable<string[]>[] = [];
    addAvancement: number[] = [];
    avancements: {} = {};
    currentIndiceBrut: string[] = [];
    grilleDescriptionSelectedText: string[] = [];
    grilleTypeSelectedText: string[] = [];
    isSubmitting: boolean = false;
    @ViewChild('item', {static: true}) accordion;


    abbatements: KeyValueSupport[] = [
        {value: '', viewValue: '', disabled: false},
        {value: '20', viewValue: '20%', disabled: false},
        {value: '80', viewValue: '80%', disabled: false},
        {value: '85', viewValue: '85%', disabled: false},
    ];
    dureeDetachement: KeyValueSupport[] = [
        {value: 'Durée illimitée', viewValue: 'Durée illimitée', disabled: false},
        {value: 'Durée 3 + 3 ans', viewValue: 'Durée 3 + 3 ans', disabled: false},
    ];
    typeAvancement: KeyValueSupport[] = [
        {value: 'Avancement à l’ancienneté', viewValue: 'Avancement à l’ancienneté', disabled: false},
        {
            value: 'Avancement promotion exceptionnelle', viewValue: 'Avancement promotion exceptionnelle',
            disabled: false,
        },
    ];

    enseignantDisciplines: KeyValueSupport[] = [
        {value: 'ALLEMAND', viewValue: 'ALLEMAND', disabled: false},
        {value: 'ANGLAIS', viewValue: 'ANGLAIS', disabled: false},
        {value: 'ARABE', viewValue: 'ARABE', disabled: false},
        {value: 'ARTS PLASTIQUES', viewValue: 'ARTS PLASTIQUES', disabled: false},
        {value: 'BASE VOILE', viewValue: 'BASE VOILE', disabled: false},
        {value: 'BUREAUTIQUE', viewValue: 'BUREAUTIQUE', disabled: false},
        {value: 'COMMUNICATION/BUREAUTIQUE', viewValue: 'COMMUNICATION/BUREAUTIQUE', disabled: false},
        {value: 'COMPTABILITE/BUREAUTIQUE', viewValue: 'COMPTABILITE/BUREAUTIQUE', disabled: false},
        {value: 'ECONOMIE ET GESTION', viewValue: 'ECONOMIE ET GESTION', disabled: false},
        {value: 'ECONOMIE/DROIT', viewValue: 'ECONOMIE/DROIT', disabled: false},
        {value: 'EDUCATION MUSICALE', viewValue: 'EDUCATION MUSICALE', disabled: false},
        {value: 'EDUCATION PHYSIQUE ET SPORTIVE', viewValue: 'EDUCATION PHYSIQUE ET SPORTIVE', disabled: false},
        {value: 'ESPAGNOL', viewValue: 'ESPAGNOL', disabled: false},
        {value: 'GENIE MECANIQUE', viewValue: 'GENIE MECANIQUE', disabled: false},
        {value: 'HISTOIRE ET GEOGRAPHIE', viewValue: 'HISTOIRE ET GEOGRAPHIE', disabled: false},
        {value: 'HISTOIRE ET GEOGRAPHIE OIB', viewValue: 'HISTOIRE ET GEOGRAPHIE OIB', disabled: false},
        {value: 'LATIN', viewValue: 'LATIN', disabled: false},
        {value: 'LETTRES', viewValue: 'LETTRES', disabled: false},
        {value: 'LETTRES CLASSIQUES', viewValue: 'LETTRES CLASSIQUES', disabled: false},
        {value: 'LETTRES MODERNES', viewValue: 'LETTRES MODERNES', disabled: false},
        {value: 'LETTRES/ALLEMAND', viewValue: 'LETTRES/ALLEMAND', disabled: false},
        {value: 'LETTRES/ANGLAIS', viewValue: 'LETTRES/ANGLAIS', disabled: false},
        {value: 'LETTRES/ESPAGNOL', viewValue: 'LETTRES/ESPAGNOL', disabled: false},
        {value: 'LETTRES/HISTOIRE', viewValue: 'LETTRES/HISTOIRE', disabled: false},
        {value: 'MATHEMATIQUES', viewValue: 'MATHEMATIQUES', disabled: false},
        {value: 'MATHEMATIQUES/ SCIENCES PHYSIQUES', viewValue: 'MATHEMATIQUES/ SCIENCES PHYSIQUES', disabled: false},
        {value: 'MUSIQUE', viewValue: 'MUSIQUE', disabled: false},
        {value: 'PHILOSOPHIE', viewValue: 'PHILOSOPHIE', disabled: false},
        {value: 'PHYSIQUE', viewValue: 'PHYSIQUE', disabled: false},
        {value: 'PHYSIQUE-CHIMIE', viewValue: 'PHYSIQUE-CHIMIE', disabled: false},
        {value: 'PRIMAIRE', viewValue: 'PRIMAIRE', disabled: false},
        {value: 'SCIENCE ET TECHNOLOGIE', viewValue: 'SCIENCE ET TECHNOLOGIE', disabled: false},
        {value: 'SCIENCES DE LA VIE ET DE LA TERRE', viewValue: 'SCIENCES DE LA VIE ET DE LA TERRE', disabled: false},
        {value: 'SCIENCES ECONOMIQUES', viewValue: 'SCIENCES ECONOMIQUES', disabled: false},
        {value: 'SCIENCES ECONOMIQUES ET SOCIALES', viewValue: 'SCIENCES ECONOMIQUES ET SOCIALES', disabled: false},
        {value: 'SCIENCES NAT/PHYSIQUE', viewValue: 'SCIENCES NAT/PHYSIQUE', disabled: false},
        {value: 'SCIENCES PHYSIQUES', viewValue: 'SCIENCES PHYSIQUES', disabled: false},
        {value: 'SCIENCES PHYSIQUES ET CHIMIQUES', viewValue: 'SCIENCES PHYSIQUES ET CHIMIQUES', disabled: false},
        {value: 'SCIENCES TECHNIQUES ET TERTIAIRES', viewValue: 'SCIENCES TECHNIQUES ET TERTIAIRES', disabled: false},
        {value: 'SECONDAIRE', viewValue: 'SECONDAIRE', disabled: false},
        {value: 'TECHNOLOGIE', viewValue: 'TECHNOLOGIE', disabled: false},
    ];

    statutContrats: KeyValueSupport[] = [
        {value: 'actif', viewValue: 'Actif', disabled: false},
        {value: 'suspendu', viewValue: 'Suspendu', disabled: false},
        {value: 'archive', viewValue: 'Archivé', disabled: false},
        {value: 'radie', viewValue: 'Radié', disabled: false},
    ];
    globalObject: GlobalObject = new GlobalObject();
    searching = false;
    searchFailed = false;
    showAnciennete = false;
    new: boolean[] = [true];
    vacation: number[] = [];
    totalVacation: number = 0;
    totalHeuresVacation: number = 0;
    primary: any = 'primary';
    ritState: boolean = false;
    private blob: Blob;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private service: ContratService,
                private personnelService: PersonnelService,
                private etablissementService: EtablissementService,
                private avancementService: AvancementService,
                private statutService: StatutService,
                private corpsService: CorpsService,
                private redirectPage: RedirectPage,
                private dbDate: DbDate,
                private fonctionService: FonctionService,
                private typeContratService: TypeContratService,
                private mageService: MageService,
                private ritService: RitService,
                private posteService: PosteService,
                private regimeService: RegimeService,
                private typeService: TypeService,
                private grilleService: GrilleService,
                private vacationCategorieHoraireService: VacationCategorieHoraireService,
    ) {
    }

    ngAfterViewInit(): void {

    }

    get contratsArray(): FormArray {
        return this.editForm.get('contrats') as FormArray;
    }

    get contrats() {
        return this.editForm.get('contrats') as FormArray;
    }

    // @ts-ignore
    resultPersonFormatter = (x: { result: any }) => x.nom + ' ' + x.prenom + ' [' + x.id + ']';

    inputPersonFormatter = (x: { result: PersonneModel }) => {
        // @ts-ignore
        if (x.nom && x.nom !== undefined && x.nom !== null) {
            // @ts-ignore
            return x.nom + ' ' + x.prenom + ' [' + x.id + ']';
        } else return '';
    }

    ngOnInit() {
        this.etablissements = this.etablissementService.getAll();
        this.statuts = this.statutService.getAll();
        this.corps = this.corpsService.getAll();
        this.fonctions = this.fonctionService.getAll();
        this.typeContrats = this.typeContratService.getAll();
        this.rits = this.ritService.getAll();
        this.mages = this.mageService.getAll();
        this.postes = this.posteService.getAll();
        this.vacationCategorieHoraires = this.vacationCategorieHoraireService.getAll();
        this.editForm = this.fb.group({
            contrats: this.fb.array([this.addContratFormGroup()]),
        });
        this.new.push(true);
        this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id) {
                this.vacation = [];
                this.totalVacation = 0;
                this.totalHeuresVacation = 0;
                this.service.getByPersonId(id)
                    .subscribe((data: any) => {
                        if (data._embedded.contrats.length > 0) {
                            this.employeeId = id;
                            // Define the order of statuses
                            const statusOrder = ['actif', 'suspendu', 'archive', 'radie'];

                            // Sort the contrats based on statusOrder
                            const sortedContrats = data._embedded.contrats.sort((a, b) => {
                                return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
                            });
                            this.editForm.setControl('contrats', this.setExistingContrats(data._embedded.contrats));
                            Promise.all([
                                this.patchEtablissement(data._embedded.contrats),
                                this.patchStatut(data._embedded.contrats),
                                this.patchFonction(data._embedded.contrats),
                                this.patchCorps(data._embedded.contrats),
                                this.patchContrats(data._embedded.contrats),
                                this.patchMage(data._embedded.contrats),
                                this.patchPoste(data._embedded.contrats),
                                this.patchQuotiteHebdomadaire(data._embedded.contrats)
                            ]).then(() => {
                                // All patch methods have completed
                            }).catch(error => {
                                // Handle errors
                                console.error('Error in patch methods', error);
                            });
                        }
                    });
            }
        });
        this.editForm.valueChanges.subscribe((data) => {
            this.logValidationErrors(this.editForm);
        });
    }

    logValidationErrors(group: FormGroup = this.editForm): void {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.get(key);
            if (abstractControl) {
                if (!abstractControl.valid && key !== 'datePromotion') {
                    abstractControl.markAsTouched();
                }
            }
            if (abstractControl instanceof FormGroup) {
                this.logValidationErrors(abstractControl);
            }
            if (abstractControl instanceof FormArray) {
                for (const contrdol of abstractControl.controls) {
                    if (contrdol instanceof FormGroup) {
                        this.logValidationErrors(contrdol);
                    }
                }
            }
        });
    }

    addContratFormGroup(): FormGroup {
        return this.fb.group({
            id: [''],
            classeEchelle: [''],
            indiceBrut: [''],
            duree: [''],
            indiceMajore: [''],
            chevron: [''],
            grade: [''],
            echelon: [''],
            nombreHeures: [''],
            isvl: [''],
            cexpat: [''],
            reliquat: [''],
            abbat: [''],
            numeroPoste: [''],
            matriculeAgence: [''],
            numeroSom: [''],
            edt: [''],
            numen: [''],
            groupech: [''],
            academie: [''],
            ministere: [''],
            noteAdm: [''],
            notePre: [''],
            anneeNote: [''],
            typeAvancement: [''],
            dureeDetachement: [],
            dateProchainePromotion: [''],
            dateProchainePrimeAnciennete: [''],
            prochainePrimeAnciennete: [''],
            observationProchainePrimeAnciennete: [''],
            appreciation: [''],
            periodeDetachement: [''],
            dateDebutDetachement: [''],
            dateFinDetachement: [''],
            dateMaroc: [''],
            dateContrat: [''],
            datePromotion: [null],
            promotionMoisGagnes: [],
            dateAvancement: [null],
            dateAppreciation: [''],
            dateCdd: [''],
            dateFinCdd: [''],
            cddHeure: [null],
            cddMajoration: [null],
            vacationCategorieHoraire: [null],
            cddTauxHoraire: [null],
            cddMotif: [null],
            dateCdi: [''],
            dateEntreeErc: ['', [Validators.required]],
            dateSortieErc: [''],
            dateArchivage: [''],
            dateDebutSuspension: [''],
            dateFinSuspension: [''],
            motifSuspension: [''],
            remplacant: [''],
            radier: [''],
            rrd: [''],
            encadrement: [''],
            disponibilite: [''],
            etablissement: ['', [Validators.required]],
            statut: ['', [Validators.required]],
            status: [''],
            statusText: [''],
            statusTextValue: [''],
            fonction: ['', [Validators.required]],
            categorieRit: [''],
            categorieMage: [''],
            discipline: [''],
            corps: ['', [Validators.required]],
            contrat: [''],
            rit: [''],
            mage: [''],
            poste: [''],
            regime: ['', [Validators.required]],
            type: ['', [Validators.required]],
            codeMage: [''],
            nomMage: [''],
            statusMage: [''],
            etablissementMage: [''],
            fonctionMage: [''],
            disciplineMage: [''],
            contratMage: [''],
            nationaliteMage: [''],
            dateEmbaucheMage: [''],
            dateNaissanceMage: [''],
            etpMage: [''],
            quotiteMage: [''],
            tempsMage: [''],
            quotiteHebdoMage: [''],
            tnrMage: [''],
            gradeOrigineMage: [''],
            disciplineOrigineMage: [''],
            niveauEtudeMage: [''],
            matriculeMage: [''],
            typeMage: [''],
            grilleCategorie: [''],
            grilleDescription: [''],
            grilleNiveau: [''],
            grilleFonction: [''],
            grilleType: [''],
            grilleRit: [''],
            grille: [''],
            quotiteHebdomadaires: this.fb.array([]),

        });
    }

    addQuotiteHebdomadaireFormGroup(grilleCategorie: number, typeFonction: number, typeDiscipline: string): FormGroup {
        let quotiteContractuelle = '';
        switch (grilleCategorie) {
            case 1: // PERSONNELS OUVRIERS ET DE SERVICE
                quotiteContractuelle = '44h';
                break;
            case 2: // AGENTS SPECIALISES DES ECOLES MATERNELLES
            case 3: // PERSONNELS ADMINISTRATIFS ET AUX PERSONNELS DE SANTE
            case 4: // SURVEILLANTS
            case 6: // CONSEILLERS PRINCIPAUX D’EDUCATION
            case 7: // TECHNICIENS ET INGENIEURS
                quotiteContractuelle = '1610h';
                break;
            case 5: // PERSONNELS ENSEIGNANTS
                if ([33, 38, 84].includes(typeFonction)) {
                    if (typeDiscipline === 'PRIMAIRE') {
                        quotiteContractuelle = '27h';
                    } else if (typeDiscipline === 'EDUCATION PHYSIQUE ET SPORTIVE') {
                        quotiteContractuelle = '20h';
                    } else {
                        quotiteContractuelle = '18h';
                    }
                }
                break;
            default:
                quotiteContractuelle = '';
        }
//supprimer baseQuotite
        return this.fb.group({
            id: [''],
            quotite: ['', [Validators.required, Validators.min(0)]],
            quotiteContractuelle: [quotiteContractuelle],
            anneeScolaire: ['', [Validators.required]],
            dateDebut: ['', [Validators.required]],
            dateFin: ['', [Validators.required]],
        }, { validators: this.quotiteValidator.bind(this) });
    }

    // Custom number format validator
    quotiteValidator(group: FormGroup): { [key: string]: any } | null {
        const quotiteControl = group.get('quotite');
        const baseQuotiteControl = group.get('baseQuotite');

        if (!quotiteControl || !baseQuotiteControl) {
            return null; // Return null if controls are not available
        }

        const quotiteValue = parseFloat(quotiteControl.value);
        const baseQuotiteValue = parseFloat(baseQuotiteControl.value);

        if (isNaN(quotiteValue) || isNaN(baseQuotiteValue)) {
            return null; // Return null if values are not numbers
        }

        return quotiteValue <= baseQuotiteValue ? null : {quotiteGreaterThanBase: true};
    }

    getQuotiteControl(parentIndex: number, childIndex: number): FormControl {
        const parentArray = this.editForm.get('contrats') as FormArray;
        const parentGroup = parentArray.at(parentIndex) as FormGroup;
        const quotiteHebdomadaires = parentGroup.get('quotiteHebdomadaires') as FormArray;
        const childGroup = quotiteHebdomadaires.at(childIndex) as FormGroup;
        return childGroup.get('quotite') as FormControl;
    }

    onQuotiteInput(event: any, parentIndex: number, childIndex: number) {
        const inputValue = event.target.value;

        // Use a regular expression to remove any characters that are not numbers or a period (.)
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');

        // Update the input value with the sanitized value
        event.target.value = sanitizedValue;

        // Update the form control's value with the sanitized value
        this.getQuotiteControl(parentIndex, childIndex).setValue(sanitizedValue);
    }

    onQuotiteKeyDown(event: KeyboardEvent) {
        const allowedCharacters = /[0-9.]/;

        if (!allowedCharacters.test(event.key)) {
            event.preventDefault();
        }
    }

    onQuotitePaste(event: ClipboardEvent, parentIndex: number, childIndex: number) {
        const clipboardData = event.clipboardData.getData('text/plain');
        const sanitizedValue = clipboardData.replace(/[^0-9.]/g, '');

        // Prevent the default paste behavior
        event.preventDefault();

        // Update the input value with the sanitized value
        this.getQuotiteControl(parentIndex, childIndex).setValue(sanitizedValue);
    }

    addQuotiteHebdomadaireButtonClick(contractIndex: number): void {
        const contratsArray = this.editForm.get('contrats') as FormArray;
        if (contractIndex >= 0 && contractIndex < contratsArray.length) {
            const contractFormGroup = contratsArray.at(contractIndex) as FormGroup;
            const quotiteHebdomadairesArray = contractFormGroup.get('quotiteHebdomadaires') as FormArray;
            const grilleCategorie = contractFormGroup.get('grilleCategorie').value;
            const typeFonction = contractFormGroup.get('fonction').value;
            const typeDiscipline = contractFormGroup.get('discipline').value;

            if (quotiteHebdomadairesArray instanceof FormArray) {
                quotiteHebdomadairesArray.push(this.addQuotiteHebdomadaireFormGroup(grilleCategorie, typeFonction, typeDiscipline));
            } else {
                console.error('quotiteHebdomadaires is not a FormArray');
            }
        }
    }

    deleteQuotiteHebdomadaire(contractIndex: number, quotiteIndex: number): void {
        const contratsArray = this.editForm.get('contrats') as FormArray;
        const contractFormGroup = contratsArray.at(contractIndex) as FormGroup;
        const quotiteHebdomadairesArray = contractFormGroup.get('quotiteHebdomadaires') as FormArray;

        if (quotiteIndex >= 0 && quotiteIndex < quotiteHebdomadairesArray.length) {
            quotiteHebdomadairesArray.removeAt(quotiteIndex);
        }
    }

    getQuotiteHebdomadaires(contratIndex: number): FormArray {
        return this.contratsArray.at(contratIndex).get('quotiteHebdomadaires') as FormArray;
    }

    onFormSubmit() {
        if (this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;

        Swal.fire({
            title: 'Modification contrat',
            text: 'Etes vous sur de vouloir apporter ces modifications?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                this.route.parent.paramMap.subscribe(async params => {
                    const id: number = Number(params.get('id'));
                    let isAvancement = false;

                    if (id) {
                        if (Object.keys(this.avancements).length > 0) {
                            const result2 = await Swal.fire({
                                title: 'Avancement detecté',
                                text: 'Voulez-vous ajouter cet un avancement?',
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Oui, je confirme',
                                cancelButtonText: 'Annuler',
                            });

                            if (result2.value) {
                                isAvancement = true;
                            }
                        }
                        this.avancements = isAvancement ? this.avancements : {};
                        // Call the refactored addToPerson
                        this.service.addToPerson(id, this.editForm, this.avancements).subscribe({
                            next: () => {
                                this.isSubmitting = false;
                                Swal.fire('Success', 'Votre modification a été bien enregistrée.', 'success');

                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: `Votre modification a été bien enregistrée.`,
                                }).then(() => this.ngOnInit());

                            },
                            error: (err) => {
                                console.error('Error processing contracts:', err);
                                Swal.fire('Error', 'Failed to save modifications.', 'error');
                                this.isSubmitting = false;
                            }
                        });
                    }
                });
            } else {
                this.isSubmitting = false;
            }
        }).catch((error) => {
            console.error('Swal error', error);
            this.isSubmitting = false;
        });
    }


    createAvancementObject(index: number): void {
        if ((<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut').value !== this.currentIndiceBrut[index]) {
            const avancement = {
                'id': 0,
                'contrat': `${environment.serverUrl}/contrats/${(<FormArray>this.editForm.controls['contrats']).at(index).get('id').value}`,
                'grilleType': this.grilleTypeSelectedText[index],
                'grilleDescription': this.grilleDescriptionSelectedText[index],
                'echelon': (<FormArray>this.editForm.controls['contrats']).at(index).get('echelon').value,
                'duree': (<FormArray>this.editForm.controls['contrats']).at(index).get('duree').value,
                'indiceBrut': Number((<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut').value) ? (<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut').value : 0,
                'reliquat': (<FormArray>this.editForm.controls['contrats']).at(index).get('reliquat').value,
                'typeAvancement': (<FormArray>this.editForm.controls['contrats']).at(index).get('typeAvancement').value,
                'promotionMoisGagnes': Number((<FormArray>this.editForm.controls['contrats']).at(index).get('promotionMoisGagnes').value) ? (<FormArray>this.editForm.controls['contrats']).at(index).get('promotionMoisGagnes').value : 0,
                'dateAvancement': '',
                'datePromotion': '',
                'action': 'POST',
            };
            if ((<FormArray>this.editForm.controls['contrats']).at(index).get('dateAvancement').value !== undefined
                && (<FormArray>this.editForm.controls['contrats']).at(index).get('dateAvancement').value !== null
                && (<FormArray>this.editForm.controls['contrats']).at(index).get('dateAvancement').value !== '') {
                avancement.dateAvancement = this.dbDate.format((<FormArray>this.editForm.controls['contrats']).at(index).get('dateAvancement').value);
            }
            if ((<FormArray>this.editForm.controls['contrats']).at(index).get('datePromotion').value !== undefined
                && (<FormArray>this.editForm.controls['contrats']).at(index).get('datePromotion').value !== null
                && (<FormArray>this.editForm.controls['contrats']).at(index).get('datePromotion').value !== '') {
                avancement.datePromotion = this.dbDate.format((<FormArray>this.editForm.controls['contrats']).at(index).get('datePromotion').value);
            }
            this.avancements[(<FormArray>this.editForm.controls['contrats']).at(index).get('id').value] = avancement;
        }
    }

    addContratButtonClick(): void {
        (<FormArray>this.editForm.get('contrats')).push(this.addContratFormGroup());
        if (this.new.length === 0) {
            this.new[0] = true;
        } else {
            this.new[this.new.length] = true;
        }
    }

    toggle() {
        this.accordion.toggle();
    }

    ritChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        this.grilleCategories[index] = this.grilleService.getCategories(rit);
    }

    categoryChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const categorieId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie').value);
        this.grilleNiveaus[index] = this.grilleService.getNiveaus(rit, categorieId);
    }

    updateQuotiteContractuelle(contratGroup: FormGroup) {
        const grilleCategorie = contratGroup.get('grilleCategorie').value;
        const fonction = contratGroup.get('fonction').value;
        const discipline = contratGroup.get('discipline') ? contratGroup.get('discipline').value : '';

        let quotiteContractuelle = '';

        switch (grilleCategorie) {
            case 5: // PERSONNELS ENSEIGNANTS
                if (fonction === 5) { // PROFESSEUR DES ECOLES
                    quotiteContractuelle = '27h';
                } else if (fonction === 33) { // ENSEIGNANT
                    if (discipline === 'EDUCATION PHYSIQUE ET SPORTIVE') {
                        quotiteContractuelle = '20h';
                    } else {
                        quotiteContractuelle = '18h';
                    }
                }
                break;
            case 1: // PERSONNELS OUVRIERS ET DE SERVICE
                quotiteContractuelle = '44h';
                break;
            case 2: // AGENTS SPECIALISES DES ECOLES MATERNELLES
            case 3: // PERSONNELS ADMINISTRATIFS ET AUX PERSONNELS DE SANTE
            case 4: // SURVEILLANTS
            case 6: // CONSEILLERS PRINCIPAUX D’EDUCATION
            case 7: // TECHNICIENS ET INGENIEURS
                quotiteContractuelle = '1610h';
                break;
            default:
                quotiteContractuelle = '';
        }

        contratGroup.get('quotiteContractuelle').setValue(quotiteContractuelle, { emitEvent: false });
    }

    levelChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const categorieId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie').value);
        const niveauId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleNiveau').value);
        this.grilleFonctions[index] = this.grilleService.getFonctions(rit, categorieId, niveauId);
    }

    functionChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const categorieId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie').value);
        const niveauId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleNiveau').value);
        const fonctionId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleFonction').value);
        this.grilleTypes[index] = this.grilleService.getTypes(rit, categorieId, niveauId, fonctionId);
    }

    typeChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const categorieId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie').value);
        const niveauId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleNiveau').value);
        const fonctionId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleFonction').value);
        const grilleTypeId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleType').value);
        (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleDescription').patchValue('');
        (<FormArray>this.editForm.controls['contrats']).at(index).get('grille').patchValue('');
        (<FormArray>this.editForm.controls['contrats']).at(index).get('echelon').patchValue('');
        (<FormArray>this.editForm.controls['contrats']).at(index).get('duree').patchValue('');
        (<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut').patchValue('');
        this.grilleDescriptions[index] =
            this.grilleService.getDescriptions(rit, categorieId, niveauId, fonctionId, grilleTypeId);

    }

    descriptionChangedHandler($event: Event, index: number) {
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const categorieId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie').value);
        const niveauId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleNiveau').value);
        const fonctionId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleFonction').value);
        const grilleTypeId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('grilleType').value);
        const grilleDescription: string =
            (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleDescription').value;
        this.grilleEchelons[index] =
            this.grilleService.getEchelons(rit, categorieId, niveauId, fonctionId, grilleTypeId, grilleDescription);

        const selectElement = $event.target as HTMLSelectElement;
        this.grilleDescriptionSelectedText[index] = selectElement.options[selectElement.selectedIndex].text;

    }

    echelonChangedHandler($event: Event, index: number) {
        const contratControl = (<FormArray>this.editForm.controls['contrats']).at(index);

        // Extract values from the form
        const contratId: number = Number(contratControl.get('id').value);
        const status: string = contratControl.get('status').value;
        const rit: string = contratControl.get('grilleRit').value;
        const categorieId: number = Number(contratControl.get('grilleCategorie').value);
        const niveauId: number = Number(contratControl.get('grilleNiveau').value);
        const fonctionId: number = Number(contratControl.get('grilleFonction').value);
        const grilleTypeId: number = Number(contratControl.get('grilleType').value);
        const grilleDescription: string = contratControl.get('grilleDescription').value;
        const echelon: string = contratControl.get('echelon').value;
        const datePromotion: string = contratControl.get('datePromotion').value;
        const reliquat: number = Number(contratControl.get('reliquat').value);

        // Clear existing values
        contratControl.get('duree').patchValue('');
        contratControl.get('indiceBrut').patchValue('');

        // Create a PromotionModel object
        const promotion: PromotionModel = {
            contratId: contratId,
            rit: rit,
            datePromotion: datePromotion,
            reliquat: reliquat,
            categoryId: categorieId,
            niveauId: niveauId,
            fonctionId: fonctionId,
            grilleTypeId: grilleTypeId,
            description: grilleDescription,
            echelon: echelon,
            dateDebutSuspension: status === 'suspendu' ? contratControl.get('dateDebutSuspension').value : '',
            dateFinSuspension: status === 'suspendu' ? contratControl.get('dateFinSuspension').value : ''
        };

        // Call the service with the PromotionModel object
        this.grilleService.getDureeAndIndiceBrut(promotion).subscribe(data => {
            if (data !== undefined) {
                contratControl.get('grille').patchValue(data['id']);
                contratControl.get('duree').patchValue(data['duree']);
                contratControl.get('indiceBrut').patchValue(data['indice']);
                contratControl.get('dateProchainePromotion').patchValue(data['observation']);
                this.createAvancementObject(index);
            }
        });
    }


    personSearch = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.personnelService.search(term).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    })),
            ),
            tap(() => this.searching = false),
        )

    selectedRemplacant(item, index: number) {
    }

    typeContratChangedHandler($event: Event, index: number) {
        this.grilleRits[index] = this.grilleService.getRits();
        const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
        const contrat: number = Number((<FormArray>this.editForm.controls['contrats']).at(index).get('contrat').value);
        this.ritValidity(index, contrat, rit);
    }

    statutChangedHandler($event: Event, index: number) {
        const typeStatutId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('statut').value);
        this.regimes[index] = this.regimeService.getAllByStatutId(typeStatutId);
        (<FormArray>this.editForm.controls['contrats']).at(index).get('regime').patchValue(null);
        (<FormArray>this.editForm.controls['contrats']).at(index).get('type').patchValue(null);
        this.addRemoveValidity(index, 'regime', true);
        this.addRemoveValidity(index, 'type', true);
    }

    regimeChangedHandler($event: Event, index: number) {
        const typeRegimeId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('regime').value);
        (<FormArray>this.editForm.controls['contrats']).at(index).get('type').patchValue(null);
        this.addRemoveValidity(index, 'type', true);
        this.types[index] = this.typeService.getAllByRegimeId(typeRegimeId);
        if (typeRegimeId !== 10) {
            this.addRemoveValidity(index, 'status', true);
            const typeContratId: number =
                Number((<FormArray>this.editForm.controls['contrats']).at(index).get('contrat').value);
            if ([6, 7].includes(typeRegimeId) && typeContratId === 5) {
                this.grilleRits[index] = this.grilleService.getRits();
                const rit: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
                this.ritValidity(index, typeContratId, rit);
            }


        } else {
            (<FormArray>this.editForm.controls['contrats']).at(index).get('status').patchValue('actif');
            (<FormArray>this.editForm.controls['contrats']).at(index).get('cddHeure').patchValue(null);
            (<FormArray>this.editForm.controls['contrats']).at(index).get('cddTauxHoraire').patchValue(null);
        }
        this.updateFormValidity(index);
    }

    regimeTypeChangedHandler($event: Event, index: number) {
        this.updateFormValidity(index);
    }

    typeFonctionChangedHandler($event: Event, index: number) {
        const typeFonctionId: number =
            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('fonction').value);
        this.fonctionService.getOne(typeFonctionId).subscribe((typeFonction) => {
            if (typeFonction !== undefined && Number(typeFonction.id) > 0) {
                (<FormArray>this.editForm.controls['contrats'])
                    .at(index)
                    .get('categorieMage').patchValue(typeFonction.descriptionMage);
                (<FormArray>this.editForm.controls['contrats'])
                    .at(index)
                    .get('categorieRit').patchValue(typeFonction.descriptionRit);
            }
        });
        this.updateFormValidity(index);
    }

    certificat(id: number) {
        this.personnelService.downloadCertificatVacataire(id).subscribe(data => {
            this.blob = new Blob([data], {type: 'application/octet-stream'});
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            const now = moment().locale('fr').format();
            link.download = 'NOTIFICATION-SUPPLEANCE-' + now + '.pdf';
            link.click();
        });
    }

    statusChangedHandler($event: Event, index: number) {
        const status: string = (<FormArray>this.editForm.controls['contrats']).at(index).get('status').value;
        const fields = {
            'actif': [],
            'radie': ['dateSortieErc'],
            'suspendu': ['dateDebutSuspension', 'dateFinSuspension'],
            'archive': ['dateArchivage'],
        };
        fields[status].forEach(field => {
            this.addRemoveValidity(index, field, true);
        });
        const allFields = [];
        Object.keys(fields)
            .filter(key => (key !== status))
            .map(key => {
                fields[key].forEach(field => {
                    allFields.push(field);
                });
            });
        allFields.filter(field => !fields[status].includes(field))
            .forEach(field => {
                this.addRemoveValidity(index, field, false);
            });
        this.updateFormValidity(index);
    }

    vacationCategorieHoraireChangedHandler($event: Event, index: number) {
        const vacationCategorieHoraire: number = Number((<FormArray>this.editForm.controls['contrats'])
            .at(index)
            .get('vacationCategorieHoraire').value);
        this.vacationCategorieHoraireService.getOne(vacationCategorieHoraire)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats'])
                        .at(index)
                        .get('cddTauxHoraire')
                        .patchValue(data.cddTauxHoraire);
                }
            });
    }

    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }

    private setExistingContrats(contrats: ContratModel[]): FormArray {
        const formArray = new FormArray([]);
        contrats.forEach((contrat, index) => {
            this.new[index] = false;
            let statusText = 'info';
            let statusTextValue = 'Actif';
            switch (contrat.status) {
                case 'actif':
                    statusText = 'success';
                    statusTextValue = 'Actif';
                    this.new[index] = true;
                    break;
                case 'archive':
                    statusText = 'primary';
                    statusTextValue = 'Archivé';
                    break;
                case 'suspendu':
                    statusText = 'warning';
                    statusTextValue = 'Suspendu';
                    break;
                case 'radie':
                    statusText = 'danger';
                    statusTextValue = 'Radié';
                    break;
            }
            if (contrat.status === 'suspendu') {
                this.patchRemplacant(contrats);
            }
            formArray.push(
                this.fb.group({
                    id: contrat.id,
                    classeEchelle: contrat.classeEchelle,
                    indiceBrut: contrat.indiceBrut,
                    duree: '',
                    indiceMajore: contrat.indiceMajore,
                    chevron: contrat.chevron,
                    grade: contrat.grade,
                    echelon: contrat.echelon,
                    nombreHeures: contrat.nombreHeures,
                    isvl: contrat.isvl,
                    cexpat: contrat.cexpat,
                    reliquat: contrat.reliquat,
                    abbat: contrat.abbat,
                    numeroPoste: contrat.numeroPoste,
                    matriculeAgence: contrat.matriculeAgence,
                    numeroSom: contrat.numeroSom,
                    edt: contrat.edt,
                    groupech: contrat.groupech,
                    radier: contrat.radier,
                    rrd: contrat.rrd,
                    numen: contrat.numen,
                    encadrement: contrat.encadrement,
                    disponibilite: contrat.disponibilite,
                    academie: contrat.academie,
                    ministere: contrat.ministere,
                    noteAdm: contrat.noteAdm,
                    notePre: contrat.notePre,
                    anneeNote: contrat.anneeNote,
                    dateProchainePromotion: contrat.dateProchainePromotion,
                    dateProchainePrimeAnciennete: '',
                    prochainePrimeAnciennete: '',
                    observationProchainePrimeAnciennete: '',
                    appreciation: contrat.appreciation,
                    typeAvancement: contrat.typeAvancement,
                    dureeDetachement: contrat.dureeDetachement,
                    periodeDetachement: contrat.periodeDetachement,
                    dateDebutDetachement: contrat.dateDebutDetachement,
                    dateFinDetachement: contrat.dateFinDetachement,
                    dateMaroc: contrat.dateMaroc,
                    dateContrat: contrat.dateContrat,
                    datePromotion: contrat.datePromotion,
                    promotionMoisGagnes: contrat.promotionMoisGagnes,
                    dateAvancement: contrat.dateAvancement,
                    dateAppreciation: contrat.dateAppreciation,
                    dateCdd: contrat.dateCdd,
                    dateFinCdd: contrat.dateFinCdd,
                    cddHeure: contrat.cddHeure,
                    cddMajoration: contrat.cddMajoration,
                    vacationCategorieHoraire: contrat.vacationCategorieHoraire,
                    cddTauxHoraire: contrat.cddTauxHoraire,
                    cddMotif: contrat.cddMotif,
                    dateCdi: contrat.dateCdi,
                    dateEntreeErc: contrat.dateEntreeErc,
                    dateSortieErc: contrat.dateSortieErc,
                    dateArchivage: contrat.dateArchivage,
                    dateDebutSuspension: contrat.dateDebutSuspension,
                    dateFinSuspension: contrat.dateFinSuspension,
                    motifSuspension: contrat.motifSuspension,
                    remplacant: contrat.remplacant,
                    etablissement: contrat.etablissement,
                    statut: contrat.statut,
                    status: contrat.status,
                    statusText: statusText,
                    statusTextValue: statusTextValue,
                    discipline: contrat.discipline,
                    fonction: contrat.fonction,
                    categorieMage: '',
                    categorieRit: '',
                    corps: contrat.corps,
                    contrat: contrat.contrat,
                    rit: contrat.rit,
                    mage: contrat.mage,
                    regime: contrat.regime,
                    type: '',
                    codeMage: '',
                    nomMage: '',
                    prenomMage: '',
                    etablissementMage: '',
                    fonctionMage: '',
                    disciplineMage: '',
                    contratMage: '',
                    nationaliteMage: '',
                    dateEmbaucheMage: '',
                    dateNaissanceMage: '',
                    etpMage: '',
                    quotiteMage: '',
                    tempsMage: '',
                    quotiteHebdoMage: '',
                    tnrMage: '',
                    gradeOrigineMage: '',
                    disciplineOrigineMage: '',
                    niveauEtudeMage: '',
                    matriculeMage: '',
                    typeMage: '',
                    statusMage: '',
                    grilleCategorie: '',
                    grilleDescription: '',
                    grilleNiveau: '',
                    grilleFonction: '',
                    grilleType: '',
                    grilleRit: '',
                    grille: '',
                    poste: '',
                    quotiteHebdomadaires: this.fb.array([]),
                }),
            );
        });
        return formArray;
    }

    private patchVacationCategorieHoraire(contrat: ContratModel, index: number): void {
        this.personnelService.getPersoneByUrl(contrat._links.vacationCategorieHoraire.href)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats'])
                        .at(index)
                        .get('vacationCategorieHoraire').patchValue(data);
                }
                this.updateFormValidity(index);
            });
    }

    private patchStatut(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.statutService.getByUrl(contrat._links.typeStatut.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index).get('statut').patchValue(data.id);
                        this.regimes[index] = this.regimeService.getAllByStatutId(data.id);
                        this.patchRegime(contrat, index);
                    }
                });
        });
    }

    private patchType(contrat: ContratModel, index): void {
        this.typeService.getByUrl(contrat._links.type.href)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats']).at(index).get('type').patchValue(data.id);
                }
                this.updateFormValidity(index);
            });
    }

    private patchEtablissement(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.etablissementService.getByUrl(contrat._links.etablissement.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index).get('etablissement').patchValue(data.id);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchFonction(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.fonctionService.getByUrl(contrat._links.typeFonction.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats'])
                            .at(index)
                            .get('fonction').patchValue(data.id);
                        (<FormArray>this.editForm.controls['contrats'])
                            .at(index)
                            .get('categorieMage').patchValue(data.descriptionMage);
                        (<FormArray>this.editForm.controls['contrats'])
                            .at(index)
                            .get('categorieRit').patchValue(data.descriptionRit);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchCorps(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.corpsService.getByUrl(contrat._links.typeCorps.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index).get('corps').patchValue(data.id);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchContrats(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.typeContratService.getByUrl(contrat._links.typeContrat.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index).get('contrat').patchValue(data.id);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchMage(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.mageService.getByUrl(contrat._links.typeMage.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index).get('mage').patchValue(data.id);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchRit(contrat: ContratModel, index: number): void {
        this.ritService.getByUrl(contrat._links.typeRit.href)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats']).at(index).get('rit').patchValue(data.id);
                }
                this.updateFormValidity(index);
            });
    }

    private patchQuotiteHebdomadaire(contrats: ContratModel[]): void {
        contrats.forEach((contrat, contratIndex) => {
            this.service.getByUrl(contrat._links.quotiteHebdomadaires.href)
                .subscribe((response: any) => {
                    const quotiteHebdomadairesData = response._embedded?.quotiteHebdomadaires;
                    if (quotiteHebdomadairesData && quotiteHebdomadairesData.length > 0) {
                        const contratsArray = this.editForm.get('contrats') as FormArray;
                        const quotiteHebdomadairesArray =
                            contratsArray.at(contratIndex).get('quotiteHebdomadaires') as FormArray;
                        const grilleCategorie = contratsArray.at(contratIndex).get('grilleCategorie').value;
                        const typeFonction = contratsArray.at(contratIndex).get('fonction').value;
                        const typeDiscipline = contratsArray.at(contratIndex).get('discipline').value;

                        quotiteHebdomadairesData.forEach((quotiteData: any, index: number) => {
                            if (quotiteHebdomadairesArray.length <= index) {
                                // Add new FormGroup if necessary
                                quotiteHebdomadairesArray.push(this.addQuotiteHebdomadaireFormGroup(grilleCategorie, typeFonction, typeDiscipline));
                            }
                            const quotiteFormGroup = quotiteHebdomadairesArray.at(index) as FormGroup;

                            if (quotiteData.quotiteContractuelle === undefined || !quotiteData.quotiteContractuelle) {
                                quotiteData.quotiteContractuelle = '18h';
                            }
                            quotiteFormGroup.patchValue({
                                id: quotiteData.id,
                                quotite: quotiteData.quotite,
                                quotiteContractuelle: quotiteData.quotiteContractuelle,
                                baseQuotite: quotiteData.baseQuotite,
                                anneeScolaire: quotiteData.anneeScolaire,
                                dateDebut: quotiteData.dateDebut,
                                dateFin: quotiteData.dateFin,
                            });
                        });
                    }
                    this.updateFormValidity(contratIndex);
                });
        });
    }

    private patchPoste(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.posteService.getByUrl(contrat._links.poste.href)
                .subscribe((data: any) => {
                    if (data !== undefined && Number(data.id) > 0) {
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('poste').patchValue(data.id);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('etablissementMage').patchValue(data.etablissementMage);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('contratMage').patchValue(data.contrat);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('dateEmbaucheMage').patchValue(moment(data.dateEmbauche).format('DD/MM/yyyy'));
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('dateNaissanceMage').patchValue(moment(data.dateNaissance).format('DD/MM/yyyy'));
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('codeMage').patchValue(data.description);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('disciplineMage').patchValue(data.discipline);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('etpMage').patchValue(data.etp);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('fonctionMage').patchValue(data.fonction);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('nationaliteMage').patchValue(data.nationalite);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('nomMage').patchValue(data.nom);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('prenomMage').patchValue(data.prenom);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('quotiteMage').patchValue(data.quotite);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('tempsMage').patchValue(data.temps);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('tnrMage').patchValue(data.tnr);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('matriculeMage').patchValue(data.matricule);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('statusMage').patchValue(data.status);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('typeMage').patchValue(data.type);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('disciplineOrigineMage').patchValue(data.disciplineOrigine);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('niveauEtudeMage').patchValue(data.niveauEtude);
                        (<FormArray>this.editForm.controls['contrats']).at(index)
                            .get('quotiteHebdoMage').patchValue(data.quotiteHebdo);
                    }
                    this.updateFormValidity(index);
                });
        });
    }

    private patchRegime(contrat: ContratModel, index): void {
        this.regimeService.getByUrl(contrat._links.typeRegime.href)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats']).at(index).get('regime').patchValue(data.id);
                    this.types[index] = this.typeService.getAllByRegimeId(data.id);
                    this.patchType(contrat, index);
                    if (Number(data.id) === 10) {
                        const startDate = (<FormArray>this.editForm.controls['contrats']).at(index).get('dateCdd').value;
                        const endDate = (<FormArray>this.editForm.controls['contrats']).at(index).get('dateFinCdd').value;
                        const diffInDays = moment(endDate).diff(moment(startDate), 'days') + 1;
                        this.vacation[index] = diffInDays;
                        if (index === 0) this.totalVacation = 0;
                        this.totalVacation += diffInDays;
                        if (index === 0) this.totalHeuresVacation = 0;
                        this.totalHeuresVacation +=
                            Number((<FormArray>this.editForm.controls['contrats']).at(index).get('cddHeure').value);
                        this.patchRemplacantByIndex(contrat, index);
                        this.patchVacationCategorieHoraire(contrat, index);
                    } else if ([6, 7].includes(Number(data.id))) {
                        this.patchRit(contrat, index);
                        this.patchGrille(contrat, index);
                    }
                }
                this.updateFormValidity(index);
            });
    }

    private patchGrille(contrat: ContratModel, index: number): void {
        this.grilleService.getByUrl(contrat._links.grille.href).pipe(
            map(grille => {
                return grille;
            }),
            mergeMap(grille => {
                if (grille !== undefined && Number(grille.id) > 0) {
                    this.grilleRits[index] = this.grilleService.getRits();
                    // @ts-ignore
                    if (grille.description !== undefined && grille.description !== null && ['E3', 'E3P', 'E4', 'E4P', 'E5', 'E5P'].includes(grille.description)) {
                        this.showAnciennete = true;
                        this.route.parent.paramMap.subscribe(params => {
                            const id: number = Number(params.get('id'));
                            if (id) {
                                this.personnelService.getPersonnelAnciennete(id, (<FormArray>this.editForm.controls['contrats']).at(index).get('etablissement').value).subscribe((entity: any) => {
                                        (<FormArray>this.editForm.controls['contrats']).at(index).get('dateProchainePrimeAnciennete')
                                            .patchValue(moment(entity.dateProchainePrimeAnciennete).format('DD/MM/yyyy'));
                                        (<FormArray>this.editForm.controls['contrats']).at(index).get('prochainePrimeAnciennete')
                                            .patchValue(entity.prochainePrimeAnciennete);
                                        (<FormArray>this.editForm.controls['contrats']).at(index).get('observationProchainePrimeAnciennete')
                                            .patchValue(
                                                `Prochaine date d'anciennete sera le ${moment(entity.dateProchainePrimeAnciennete).format('DD/MM/yyyy')} et la prime à appliquer sera de ${entity.prochainePrimeAnciennete}%`,
                                            );
                                    }, (err: any) => {
                                    },
                                );
                            }
                        });
                    }
                    return this.grilleService.getById(grille.id);
                } else {
                    this.grilleRits[index] = this.grilleService.getRits();
                    (<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut')
                        .patchValue('');
                    return new Observable<GrilleModel>();
                }
            }),
        ).subscribe(value => {
            if (value !== undefined && Number(value.id) > 0) {
                this.grilleCategories[index] = this.grilleService.getCategories(value.rit);
                this.grilleNiveaus[index] = this.grilleService.getNiveaus(value.rit, value.categorieId);
                this.grilleFonctions[index] =
                    this.grilleService.getFonctions(value.rit, value.categorieId, value.niveauId);
                this.grilleTypes[index] =
                    this.grilleService.getTypes(value.rit, value.categorieId, value.niveauId, value.fonctionId);
                this.grilleDescriptions[index] =
                    this.grilleService.getDescriptions(value.rit, value.categorieId, value.niveauId,
                        value.fonctionId, value.typeId);
                this.grilleEchelons[index] =
                    this.grilleService.getEchelons(value.rit, value.categorieId, value.niveauId, value.fonctionId,
                        value.typeId, value.description);


                (<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut')
                    .patchValue(value.indice);
                (<FormArray>this.editForm.controls['contrats']).at(index).get('duree')
                    .patchValue(value.duree);
                (<FormArray>this.editForm.controls['contrats']).at(index).get('grille')
                    .patchValue(value.id);
                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit')
                    .patchValue(value.rit);
                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleCategorie')
                    .patchValue(value.categorieId);

                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleNiveau')
                    .patchValue(value.niveauId);

                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleFonction')
                    .patchValue(value.fonctionId);

                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleType')
                    .patchValue(value.typeId);
                this.grilleTypeSelectedText[index] = value.typeDescription;

                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleDescription')
                    .patchValue(value.description);
                this.grilleDescriptionSelectedText[index] = value.description;

                (<FormArray>this.editForm.controls['contrats']).at(index).get('echelon')
                    .patchValue(value.echelon);

                const contratId: number = Number((<FormArray>this.editForm.controls['contrats'])
                    .at(index).get('id').value);
                const datePromotion: string = (<FormArray>this.editForm.controls['contrats'])
                    .at(index).get('datePromotion').value;
                const reliquat: number = Number((<FormArray>this.editForm.controls['contrats'])
                    .at(index).get('reliquat').value);

                const status: string = (<FormArray>this.editForm.controls['contrats'])
                    .at(index).get('status').value;


                const promotion: PromotionModel = {
                    contratId: contratId,
                    rit: value.rit,
                    datePromotion: datePromotion,
                    reliquat: reliquat,
                    categoryId: value.categorieId,
                    niveauId: value.niveauId,
                    fonctionId: value.fonctionId,
                    grilleTypeId: value.typeId,
                    description: value.description,
                    echelon: value.echelon,
                    dateDebutSuspension: status === 'suspendu' ? (<FormArray>this.editForm.controls['contrats']).at(index).get('dateDebutSuspension').value : '',
                    dateFinSuspension: status === 'suspendu' ? (<FormArray>this.editForm.controls['contrats']).at(index).get('dateFinSuspension').value : ''
                };


                this.grilleService
                    .getDureeAndIndiceBrut(promotion)
                    .subscribe(data => {
                        if (data !== undefined) {
                            (<FormArray>this.editForm.controls['contrats']).at(index).get('grille').patchValue(data['id']);
                            (<FormArray>this.editForm.controls['contrats']).at(index).get('duree').patchValue(data['duree']);
                            (<FormArray>this.editForm.controls['contrats']).at(index).get('indiceBrut').patchValue(data['indice']);
                            (<FormArray>this.editForm.controls['contrats']).at(index).get('dateProchainePromotion').patchValue(data['observation']);
                            this.currentIndiceBrut[index] = data['indice'];
                        }
                    });
            }
        });
    }

    private patchRemplacant(contrats: ContratModel[]): void {
        contrats.forEach((contrat, index) => {
            this.patchRemplacantByIndex(contrat, index);
        });
    }

    private patchRemplacantByIndex(contrat: ContratModel, index: number): void {
        this.personnelService.getPersoneByUrl(contrat._links.remplacant.href)
            .subscribe((data: any) => {
                if (data !== undefined && Number(data.id) > 0) {
                    (<FormArray>this.editForm.controls['contrats'])
                        .at(index)
                        .get('remplacant').patchValue(data);
                }
                this.updateFormValidity(index);
            });
    }

    private ritValidity(index: number, contrat: number, rit: string) {
        this.grilleRits[index] = this.grilleService.getRits();
        const fields = ['grilleRit', 'grilleCategorie', 'grilleNiveau', 'grilleFonction', 'grilleType',
            'grilleDescription', 'echelon'];
        if (contrat === 5) {
            if (rit === undefined || !rit) {
                (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit')
                    .patchValue('RIT_2023_2024');
            }
            rit = (<FormArray>this.editForm.controls['contrats']).at(index).get('grilleRit').value;
            this.grilleCategories[index] = this.grilleService.getCategories(rit);
            fields.forEach(field => {
                this.addRemoveValidity(index, field, true);
            });
        } else {
            fields.forEach(field => {
                this.addRemoveValidity(index, field, false);
            });
        }
        this.updateFormValidity(index);
    }

    private updateFormValidity(index: number) {
        const typeRegimeId: number =
            (<FormArray>this.editForm.controls['contrats'])
            && (<FormArray>this.editForm.controls['contrats']).at(index)
            && (<FormArray>this.editForm.controls['contrats']).at(index).get('regime') !== undefined ?
                Number((<FormArray>this.editForm.controls['contrats']).at(index).get('regime').value) : 0;
        const fieldsByRegimeId = {
            // Expatrié
            1: ['academie', 'ministere', 'periodeDetachement', 'dateDebutDetachement', 'dateFinDetachement',
                'matriculeAgence', 'numeroPoste', 'numen'],
            // Résident AEFE
            3: ['academie', 'ministere', 'periodeDetachement', 'dateDebutDetachement', 'dateFinDetachement',
                'matriculeAgence', 'numeroPoste', 'numen'],
            // Contrats à durée indéterminée
            6: ['contrat', 'dateCdi'],
            // Contrats à durée déterminée
            7: ['contrat', 'dateCdd', 'dateFinCdd'],
            // Mise à disposition MEN MAROC
            8: ['numeroSom', 'dateDebutDetachement', 'dateFinDetachement'],
            // Vacataires
            10: ['dateCdd', 'dateFinCdd', 'cddHeure', 'cddTauxHoraire', 'cddMotif'],
        };
        if (typeRegimeId !== undefined && typeRegimeId > 0) {
            fieldsByRegimeId[typeRegimeId].forEach(field => {
                this.addRemoveValidity(index, field, true);
            });
            let allFields = [];
            Object.keys(fieldsByRegimeId)
                .filter(key => (Number(key) !== typeRegimeId))
                .map(key => {
                    fieldsByRegimeId[key].forEach(field => {
                        allFields.push(field);
                    });
                });
            allFields = allFields.filter(function (x, i, a) {
                return a.indexOf(x) === i;
            });
            allFields.filter(field => !fieldsByRegimeId[typeRegimeId].includes(field))
                .forEach(field => {
                    this.addRemoveValidity(index, field, false);
                });
        }
        const defaultRequiredFields = ['etablissement', 'statut', 'regime', 'type'];
        defaultRequiredFields.forEach(field => {
            this.addRemoveValidity(index, field, true);
        });
    }

    private addRemoveValidity(index: number, controlName: string, required: boolean) {
        if ((<FormArray>this.editForm.controls['contrats']).at(index).get(controlName) !== null) {
            const controlObject = (<FormArray>this.editForm.controls['contrats']).at(index).get(controlName);
            if (required) {
                controlObject.setValidators([Validators.required]);
                controlObject.updateValueAndValidity();
            } else {
                controlObject.clearValidators();
                controlObject.updateValueAndValidity();
            }
        }
        {

        }
    }

    suspensionDatesChangedHandler(selectedDate: string, index: number) {
        const contratControl = (<FormArray>this.editForm.controls['contrats']).at(index);

        // Extract updated values
        const dateDebutSuspension: string = contratControl.get('dateDebutSuspension').value;
        const dateFinSuspension: string = contratControl.get('dateFinSuspension').value;

        // Ensure you have all other necessary data
        const contratId: number = Number(contratControl.get('id').value);
        const status: string = contratControl.get('status').value;
        const rit: string = contratControl.get('grilleRit').value;
        const categorieId: number = Number(contratControl.get('grilleCategorie').value);
        const niveauId: number = Number(contratControl.get('grilleNiveau').value);
        const fonctionId: number = Number(contratControl.get('grilleFonction').value);
        const grilleTypeId: number = Number(contratControl.get('grilleType').value);
        const grilleDescription: string = contratControl.get('grilleDescription').value;
        const echelon: string = contratControl.get('echelon').value;
        const datePromotion: string = contratControl.get('datePromotion').value;
        const reliquat: number = Number(contratControl.get('reliquat').value);

        // Create a PromotionModel object
        const promotion: PromotionModel = {
            contratId: contratId,
            rit: rit,
            datePromotion: datePromotion,
            reliquat: reliquat,
            categoryId: categorieId,
            niveauId: niveauId,
            fonctionId: fonctionId,
            grilleTypeId: grilleTypeId,
            description: grilleDescription,
            echelon: echelon,
            dateDebutSuspension: status === 'suspendu' ? this.dbDate.format(dateDebutSuspension) : '',
            dateFinSuspension: status === 'suspendu' ? this.dbDate.format(dateFinSuspension) : ''
        };

        // Call the service
        this.grilleService.getDureeAndIndiceBrut(promotion).subscribe(data => {
            if (data !== undefined) {
                contratControl.get('grille').patchValue(data['id']);
                contratControl.get('duree').patchValue(data['duree']);
                contratControl.get('indiceBrut').patchValue(data['indice']);
                contratControl.get('dateProchainePromotion').patchValue(data['observation']);
                this.createAvancementObject(index);
            }
        });
    }

}