import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {CustomDatepickerI18n, I18n} from '../../../../lyautey/shared/CustomDatepickerI18n';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {DatePipe} from '@angular/common';
import {NgbDateMomentParserFormatter} from '../../../../lyautey/shared/NgbDateMomentParserFormatter';
import {ContratService} from '../../../../lyautey/services/contrat.service';
import {EtablissementService} from '../../../../lyautey/services/etablissement.service';
import {FonctionService} from '../../../../lyautey/services/fonction.service';
import {forkJoin, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DbDate} from '../../../../lyautey/shared/dbDate';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';
import {SuspensionService} from "../../../../lyautey/services/suspension.service";
import {SuspensionModel} from "../../../../lyautey/model/suspension.model";

@Component({
    selector: 'ngx-suspension',
    templateUrl: './suspension.component.html',
    styleUrls: ['./suspension.component.scss'],
    providers: [
        I18n,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        DatePipe,
        {
            provide: NgbDateMomentParserFormatter,
            useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
        },
    ],
})
export class SuspensionComponent implements OnInit, CanComponentDeactivate {
    buttonText: string = 'Ajouter';
    editForm: FormGroup;
    globalObject: GlobalObject = new GlobalObject();
    suspensions: any = [];
    contrats: any = [];

    constructor(private service: SuspensionService,
                private etablissementService: EtablissementService,
                private contratService: ContratService,
                private fonctionService: FonctionService,
                private dbDate: DbDate,
                private route: ActivatedRoute,
                private fb: FormBuilder) {
    }
    ngOnInit() {
        this.editForm = this.fb.group({
            id: [''],
            contrat: ['', Validators.required],
            dateDebut: ['', Validators.required],
            dateFin: ['', Validators.required],
        });

        this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id) {
                this.contratService.getByPersonId(id).subscribe((data: any) => {
                    this.contrats = [];
                    const observables = [];

                    const filteredContrats = data._embedded.contrats;

                    if (filteredContrats.length > 0) {
                        filteredContrats.forEach((contrat) => {
                            const etablissementObservable = this.etablissementService
                                .getByUrl(contrat._links.etablissement.href);
                            const fonctionObservable = this.fonctionService
                                .getByUrl(contrat._links.typeFonction.href)
                                .pipe(catchError((error) => {
                                    console.error('Error fetching fonction:', error);
                                    return of({});
                                }));
                            const SuspensionObservable = this.contratService
                                .getByUrl(contrat._links.suspensions.href)
                                .pipe(map(SuspensionResponse => {
                                    const suspensions = SuspensionResponse['_embedded']?.suspensions || [];
                                    return suspensions;
                                }));
                            const combinedObservable =
                                forkJoin([etablissementObservable, fonctionObservable, SuspensionObservable])
                                    .pipe(map(([etablissement, typeFonction, suspensions]) => ({
                                        'contrat': contrat.id,
                                        'status': contrat.status,
                                        'fonction': typeFonction['description'] || 'N/A',
                                        'etablissement': etablissement.description || 'N/A',
                                        'suspensions': suspensions,
                                        'viewType': 'table',
                                    })));
                            observables.push(combinedObservable);
                        });

                        forkJoin(observables).subscribe((contratResults) => {
                            this.contrats = contratResults;

                            // Sort if needed, but only 'suspendu' are included, so this might not be necessary
                            const statusOrder = ['actif', 'suspendu', 'archive', 'radie'];
                            this.contrats.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
                        });
                    }
                });
            }
        });
    }


    editSuspension(suspensionModel: SuspensionModel, contrat: any) {
        this.buttonText = 'Modifier';
        this.editForm.patchValue(suspensionModel);
        this.editForm.patchValue({contrat : contrat.contrat});
    }

    resetEditForm() {
        this.buttonText = 'Ajouter';
        this.editForm.reset({
            id: '',
            contrat: '',
            dateDebut: '',
            dateFin: '',
            motifSuspension: '',
        });
    }

    deleteSuspension(id: number) {
        if (Number(id) > 0) {
            this.service.delete(id).subscribe((data: any) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Votre modification a été bien enregistrée.`,
                }).then(() => this.ngOnInit());
            }, (err: any) => {
            });
        }
    }

    beforeDelete(id: number, title, text) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                this.deleteSuspension(id);
            }
        });
    }

    onSubmit() {
        const suspension = {
            'id': this.editForm.get('id').value,
            'contratId': this.editForm.get('contrat').value,
            'dateDebut': '',
            'dateFin': '',
            'motifSuspension': '',
        };
        if (this.editForm.get('dateDebut').value !== undefined
            && this.editForm.get('dateDebut').value !== null
            && this.editForm.get('dateDebut').value !== '') {
            suspension.dateDebut = this.dbDate.format(this.editForm.get('dateDebut').value);
        }
        if (this.editForm.get('dateFin').value !== undefined
            && this.editForm.get('dateFin').value !== null
            && this.editForm.get('dateFin').value !== '') {
            suspension.dateFin = this.dbDate.format(this.editForm.get('dateFin').value);
        }
        this.service.addToContract(suspension)
            .subscribe((model: any) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Votre modification a été bien enregistrée.`,
                    }).then(() => this.ngOnInit());
                },
                (err: any) => {
                });
    }

    getCardStatus(contratStatus: string): string {
        switch (contratStatus) {
            case 'actif':
                return 'success';
            case 'suspendu':
                return 'warning';
            case 'archive':
                return 'info';
            case 'radie':
                return 'danger';
            default:
                return 'secondary';
        }
    }

    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }

}
