<ng-template #rt let-r="result" let-t="term">
  <span  [ngClass]="['flag-icon', 'flag-icon-squared', r.flag ]" style="margin-right: 5px" ></span>
  <ngb-highlight [result]="r.nomFrFr" [term]="t"></ngb-highlight>
</ng-template>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      <span>Fiche Conjoint: {{ data.personne.nomUsuel }} {{ data.personne.prenom }}</span>
      <button class="close" aria-label="Close" (click)="ref.close()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <table class="table table-bordered">
        <tbody>
        <tr>
          <th scope="col">Etablissement</th>
          <td scope="col"> {{ data.contrat.etablissement }}</td>
          <th scope="col">Statut</th>
          <td scope="col"> {{ data.contrat.statut }}</td>
        </tr>
        <tr>
          <th scope="col">Fonction</th>
          <td scope="col"> {{ data.contrat.fonction }}</td>
          <th scope="col">Regime</th>
          <td scope="col"> {{ data.contrat.regime }}</td>
        </tr>
        <tr>
          <th scope="col">Corps</th>
          <td scope="col"> {{ data.contrat.corps }}</td>
          <th scope="col">Type contrat</th>
          <td scope="col"> {{ data.contrat.contrat }}</td>
        </tr>
        <tr>
          <th scope="col">Rit</th>
          <td scope="col"> {{ data.contrat.rit }}</td>
          <th scope="col">Date d'entrée Erc</th>
          <td scope="col"> {{ data.contrat.dateEntreeErc }}</td>
        </tr>
        </tbody>
      </table>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ref.close()">Fermer</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #footerTemplate>
  <hr class="my-0">
  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Fermer</button>
</ng-template>

<form [formGroup]='personneForm' (ngSubmit)="onFormSubmit()" autocomplete="off">
  <nb-accordion multi style="margin-top: 20px;">
    <nb-accordion-item [expanded]="true">
      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Informations personnelles
      </nb-accordion-item-header>
      <nb-accordion-item-body>

        <div class="form-row" *nbIsGranted="['download', 'certificate']" >
          <div class="form-group col-md-10" >
          </div>
          <div class="form-group col-md-2 text-md-right" >
            <label *ngIf="action === 'edit'" (click)="certificat(employeeId)" style="cursor: pointer" class="btn btn-info" role="button" title="Certificat d'exercice"> <i class="fas fa-file-pdf"></i></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-11">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="control-label" for="genre">Genre</label>

                <select class="form-control" formControlName="genre" id="genre" [ngClass]="{'is-invalid': personneForm.get('genre').invalid && personneForm.get('genre').touched}" (blur)="logValidationErrors()" >
                  <option *ngFor="let item of (genres | async)" [ngValue]="item.id">
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label class="control-label" for="situationFamiliale">Situation familiale</label>
                <select class="form-control" formControlName="situationFamiliale" id="situationFamiliale" [ngClass]="{'is-invalid': personneForm.get('situationFamiliale').invalid &&  personneForm.get('situationFamiliale').touched}" (blur)="logValidationErrors()" >
                  <option *ngFor="let item of (situationFamiliales | async)" [ngValue]="item.id ">
                    {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-2" >
                <label class="control-label" for="nom">Nom de naissance</label>
                <input type="text" class="form-control" placeholder="Nom" formControlName="nom" id="nom" [ngClass]="{'is-invalid': personneForm.get('nom').invalid && personneForm.get('nom').touched}" (blur)="logValidationErrors()"  />
              </div>

              <div class="form-group col-md-3">
                <label class="control-label" for="nomUsuel">Nom d'usage</label>
                <input type="text" class="form-control" placeholder="Nom usuel" formControlName="nomUsuel" id="nomUsuel" [ngClass]="{'is-invalid': personneForm.get('nomUsuel').invalid && personneForm.get('nomUsuel').touched}" (blur)="logValidationErrors()" />
              </div>

              <div class="form-group col-md-3" >
                <label class="control-label" for="prenom" >Prénom</label>
                <input type="text" class="form-control" placeholder="Prénom(s)" formControlName="prenom"  id="prenom" [ngClass]="{'is-invalid': personneForm.get('prenom').invalid && personneForm.get('prenom').touched}" (blur)="logValidationErrors()" />
              </div>

              <div class="form-group col-md-2" >
                <label class="control-label" for="prenomDeux" >Deuxième prénom</label>
                <input type="text" class="form-control" placeholder="Deuxième prénom" formControlName="prenomDeux"  id="prenomDeux" [ngClass]="{'is-invalid': personneForm.get('prenomDeux').invalid && personneForm.get('prenomDeux').touched}" (blur)="logValidationErrors()" />
              </div>

              <div class="form-group col-md-2" >
                <label class="control-label" for="prenomTrois" >Troisième prénom</label>
                <input type="text" class="form-control" placeholder="Troisième prénom" formControlName="prenomTrois"  id="prenomTrois" [ngClass]="{'is-invalid': personneForm.get('prenomTrois').invalid && personneForm.get('prenomTrois').touched}" (blur)="logValidationErrors()" />
              </div>
            </div>
          </div>
          <div class="form-group col-md-1 text-md-right" *nbIsGranted="['view', 'document']">
            <img [src]="personnelPhoto" style="width: 116px; height: 150px; border:2px solid #3366ff" class="rounded float-left" alt="{{ matricule }}" title="{{ matricule }}" />
          </div>
        </div>

        <div class="form-row">

          <div class="form-group col-md-2" >
            <label class="control-label" for="numeroCarteNationale">Numéro CIN Maroc</label>
            <input type="text" class="form-control" placeholder="Numéro de carte d'identité nationale" formControlName="numeroCarteNationale" id="numeroCarteNationale" [ngClass]="{'is-invalid': personneForm.get('numeroCarteNationale').invalid && personneForm.get('numeroCarteNationale').touched}" (blur)="logValidationErrors()"  />
          </div>
          <div class="form-group col-md-2" >
            <label class="control-label" for="dateValiditeCarteNationale">Validité</label>
            <div class="input-group">
              <input class="form-control"  placeholder="dd/mm/yyyy" container="body"  formControlName="dateValiditeCarteNationale" id="dateValiditeCarteNationale"
                     placement="top" ngbDatepicker #dValidite="ngbDatepicker"/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="dValidite.toggle()" type="button"></button>
              </div>
            </div>
          </div>

          <div class="form-group col-md-2" >
            <label class="control-label" for="nationalite">Nationalité(s)</label>
            <ng-select [items]="countries | async"
                       bindLabel="nomFrFr"
                       [virtualScroll]="true"
                       multiple="multiple"
                       appendTo="body"
                       formControlName="nationalite" id="nationalite">
            </ng-select>

          </div>


          <div class="form-group col-md-2" >
            <label class="control-label" for="paysNaissance">Pays de naissance</label>
            <input type="text" class="form-control" formControlName="paysNaissance" id="paysNaissance" (selectItem)="selectedItem($event)"
                   [ngbTypeahead]="countrySearch" [editable]='false' container="body"
                   placeholder="Pays de naissance" [resultTemplate]="rt"  [inputFormatter]="formatter" [ngClass]="{'is-invalid': personneForm.get('paysNaissance').invalid && personneForm.get('paysNaissance').touched}" (blur)="logValidationErrors()"  />
          </div>

          <div class="form-group col-md-2" >
            <label class="control-label" for="lieuNaissance">Lieu de naissance</label>
            <input type="text" class="form-control" placeholder="Lieu de naissance" formControlName="lieuNaissance" id="lieuNaissance" [ngClass]="{'is-invalid': personneForm.get('lieuNaissance').invalid && personneForm.get('lieuNaissance').touched}" (blur)="logValidationErrors()" />
          </div>
          <div class="form-group col-md-2" >
            <label class="control-label" for="dateNaissance">Date de naissance</label>
            <div class="input-group">
              <input class="form-control"  placeholder="dd/mm/yyyy" container="body"  formControlName="dateNaissance" id="dateNaissance"
                     placement="top" ngbDatepicker #d="ngbDatepicker"
                    [ngClass]="{'is-invalid': personneForm.get('dateNaissance').invalid && personneForm.get('dateNaissance').touched}" (blur)="logValidationErrors()" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row" *ngIf="showAncienLieuNaissance">
          <div class="form-group col-md-12">
            <label class="control-label" for="ancienLieuNaissance">Ancien Lieu de naissance</label>
            <input type="text" class="form-control" placeholder="Lieu de naissance" formControlName="ancienLieuNaissance" id="ancienLieuNaissance" readonly />
          </div>
        </div>

        <div class="form-row">

          <div class="form-group col-md-4"  >
            <label class="control-label" for="email">Email personnel</label>
            <input type="text" class="form-control" placeholder="Email" formControlName="email" id="email" [ngClass]="{'is-invalid': personneForm.get('email').invalid && personneForm.get('email').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-4" >
            <label class="control-label" for="emailDeux">Email professionnel </label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Email professionnel" (blur)="logValidationErrors()" formControlName="emailDeux" id="emailDeux" [ngClass]="{'is-invalid': personneForm.get('emailDeux').invalid && personneForm.get('emailDeux').touched}" (blur)="logValidationErrors()" />
            <small aria-describedby="emailHelp" class="form-text text-muted" >{{this.validDomains}} </small>
          </div>

          <div class="form-group col-md-4" >
            <label class="control-label" for="emailAefe">Email AEFE </label>
            <input type="text" class="form-control" placeholder="Email AEFE" (blur)="logValidationErrors()" formControlName="emailAefe" id="emailAefe" [ngClass]="{'is-invalid': personneForm.get('emailAefe').invalid && personneForm.get('emailAefe').touched}" (blur)="logValidationErrors()" />
            <small  class="form-text text-muted" >{{this.validAefeDomains}}</small>
          </div>
        </div>

      </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'social_security']" >
      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Sécurité sociale
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="form-row">

          <div class="col-md-4">
            <input type="checkbox" formControlName="cnssActivated" for="cnssActivated" (click)="onCnssActivatedChange($event)" /> &nbsp;
            <label id="cnssActivated">Numéro CNSS</label>
            <input  (keypress)="onlyNumberKey($event)" minlength="9" maxlength="9" class="form-control" placeholder="Numéro CNSS" formControlName="numeroCnss" [ngClass]="{'is-invalid': personneForm.get('numeroCnss').invalid && personneForm.get('numeroCnss').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="col-md-4">
            <input type="checkbox" formControlName="cfeActivated" id="cfeActivated" (click)="onCfeActivatedChange($event)" /> &nbsp;
            <label for="cfeActivated">Numéro de sécurité sociale</label>
            <input  (keypress)="onlyNumberKey($event)" minlength="13" maxlength="15" class="form-control" placeholder="Numéro INSEE" formControlName="numeroCfe" [ngClass]="{'is-invalid': personneForm.get('numeroCfe').invalid && personneForm.get('numeroCfe').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="col-md-4">
            <input type="checkbox" formControlName="cimrActivated" id="cimrActivated" (click)="onCimrActivatedChange($event)"/> &nbsp;
            <label for="cimrActivated">Numéro CIMR</label>
            <input  (keypress)="onlyNumberKey($event)" minlength="7" maxlength="13" class="form-control" placeholder="Numéro CIMR" formControlName="numeroCimr" [ngClass]="{'is-invalid': personneForm.get('numeroCimr').invalid && personneForm.get('numeroCimr').touched}" (blur)="logValidationErrors()"/>
          </div>

        </div>
        <div class="form-row clearfix">

          <div class="col-md-4">
            <input type="checkbox" formControlName="cnopsActivated" for="cnopsActivated"  (click)="onSocialSecurityActivatedChange($event, 'numeroCnops',  7, 10, true)" /> &nbsp;
            <label id="cnopsActivated">Numéro CNOPS</label>
            <input (keypress)="onlyNumberAndCharsKey($event)" minlength="7" maxlength="10" class="form-control" placeholder="Numéro CNOPS" formControlName="numeroCnops" [ngClass]="{'is-invalid': personneForm.get('numeroCnops').invalid && personneForm.get('numeroCnops').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="col-md-4">
            <input type="checkbox" formControlName="mgenActivated" id="mgenActivated" (click)="onSocialSecurityActivatedChange($event, 'numeroMgen',  6, 9, false)" /> &nbsp;
            <label for="mgenActivated">Numéro MGEN</label>
            <input  (keypress)="onlyNumberKey($event)" minlength="6" maxlength="9" class="form-control" placeholder="Numéro MGEN" formControlName="numeroMgen" [ngClass]="{'is-invalid': personneForm.get('numeroMgen').invalid && personneForm.get('numeroMgen').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="col-md-4">
            <input type="checkbox" formControlName="mgpapActivated" id="mgpapActivated" (click)="onSocialSecurityActivatedChange($event, 'numeroMgpap',  7, 9, false)"/> &nbsp;
            <label for="mgpapActivated">Numéro MGPAP</label>
            <input  (keypress)="onlyNumberKey($event)" minlength="7" maxlength="9" class="form-control" placeholder="Numéro MGPAP" formControlName="numeroMgpap" [ngClass]="{'is-invalid': personneForm.get('numeroMgpap').invalid && personneForm.get('numeroMgpap').touched}" (blur)="logValidationErrors()"/>
          </div>

        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item [expanded]="true">
      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Contact d'urgence
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="form-row">

          <div class="form-group col-md-6">
            <label for="nomContactUrgence">Nom</label>
            <input type="text" class="form-control" placeholder="Nom" formControlName="nomContactUrgence" id="nomContactUrgence" [ngClass]="{'is-invalid': personneForm.get('nomContactUrgence').invalid && personneForm.get('nomContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-6">
            <label for="prenomContactUrgence">Prénom</label>
            <input type="text" class="form-control" placeholder="Prénom" formControlName="prenomContactUrgence" id="prenomContactUrgence" [ngClass]="{'is-invalid': personneForm.get('prenomContactUrgence').invalid && personneForm.get('prenomContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="adresseContactUrgence">Adresse</label>
            <input type="text" class="form-control" placeholder="Adresse" formControlName="adresseContactUrgence" id="adresseContactUrgence" [ngClass]="{'is-invalid': personneForm.get('adresseContactUrgence').invalid && personneForm.get('adresseContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-2">
            <label for="codePostalContactUrgence">Code postal</label>
            <input type="text" class="form-control" placeholder="Code postal" formControlName="codePostalContactUrgence" id="codePostalContactUrgence" [ngClass]="{'is-invalid': personneForm.get('codePostalContactUrgence').invalid && personneForm.get('codePostalContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-2">
            <label for="villeContactUrgence">Ville</label>
            <input type="text" class="form-control" placeholder="Ville" formControlName="villeContactUrgence" id="villeContactUrgence" [ngClass]="{'is-invalid': personneForm.get('villeContactUrgence').invalid && personneForm.get('villeContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-4">
            <label for="paysContactUrgence">Pays</label>
            <input type="text" class="form-control" formControlName="paysContactUrgence" id="paysContactUrgence"
                   [ngbTypeahead]="countrySearch" [editable]='false'
                   placeholder="Pays" container="body" [resultTemplate]="rt" [inputFormatter]="formatter" [ngClass]="{'is-invalid': personneForm.get('paysContactUrgence').invalid && personneForm.get('paysContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="mobileContactUrgence">Mobile</label>
            <input type="text" class="form-control" placeholder="Mobile" formControlName="mobileContactUrgence" id="mobileContactUrgence"  maxlength="10" [ngClass]="{'is-invalid': personneForm.get('mobileContactUrgence').invalid && personneForm.get('mobileContactUrgence').touched}" (blur)="logValidationErrors()" />
          </div>

          <div class="form-group col-md-6">
            <label>Email</label>
            <input type="text" class="form-control" placeholder="Email" formControlName="emailContactUrgence" [ngClass]="{'is-invalid': personneForm.get('emailContactUrgence').invalid && personneForm.get('emailContactUrgence').touched}" (blur)="logValidationErrors()" />

          </div>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'conjoint']" >

      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Conjoint
      </nb-accordion-item-header>

      <nb-accordion-item-body>

        <div formArrayName="conjoints">

          <div *ngFor="let conjoint of personneForm['controls'].conjoints['controls']; let i=index" >

            <div [formGroupName]="i" >
              <hr *ngIf="i>0" />
              <div class="form-row">

                <div class="form-group col-md-2">
                  <label [attr.for]="'nomDuConjoint' +i">Nom du conjoint</label>
                  <input type="hidden" class="form-control" formControlName="id" />
                  <input type="text" class="form-control" placeholder="Nom du conjoint" formControlName="nomDuConjoint" [id]="'nomDuConjoint' +i" [ngClass]="{'is-invalid': conjoint.get('nomDuConjoint').invalid && conjoint.get('nomDuConjoint').touched}" (blur)="logValidationErrors()" />
                </div>

                <div class="form-group col-md-2">
                  <label [attr.for]="'conjoint_nom_' +i">Nom</label>
                  <input type="text" class="form-control" placeholder="Nom" formControlName="nom" [id]="'conjoint_nom_' +i" [ngClass]="{'is-invalid': conjoint.get('nom').invalid && conjoint.get('nom').touched}" (blur)="logValidationErrors()" />
                </div>

                <div class="form-group col-md-2">
                  <label [attr.for]="'conjoint_prenom_' +i">Prénom</label>
                  <input type="text" class="form-control" placeholder="Prénom" formControlName="prenom" [id]="'conjoint_prenom_' +i" [ngClass]="{'is-invalid': conjoint.get('prenom').invalid && conjoint.get('prenom').touched}" (blur)="logValidationErrors()" />
                </div>

                <div class="form-group col-md-2">
                  <label [attr.for]="'profession' +i">Profession</label>
                  <select class="form-control" formControlName="profession" [id]="'profession' +i" [ngClass]="{'is-invalid': conjoint.get('profession').invalid && conjoint.get('profession').touched}" (blur)="logValidationErrors()" (change)="selectChangeHandler($event, i)" >
                    <option *ngFor="let item of (professions | async)" [ngValue]="item.id">
                      {{ item.description }}
                    </option>
                  </select>

                </div>

                <div class="form-group col-md-2">
                  <label [attr.for]="'professionMatricule' +i">Matricule</label>
                  <input type="text" class="form-control" formControlName="professionMatricule" id="professionMatricule" [id]="'professionMatricule' +i" [ngClass]="{'is-invalid': conjoint.get('professionMatricule').invalid && conjoint.get('profession').touched}" (blur)="logValidationErrors()"
                         [ngbTypeahead]="personSearch" [editable]='true' (selectItem)="selectedConjointItem($event, i)" container="body"
                         placeholder="Matricule" [resultFormatter]="formatterPerson" [inputFormatter]="formatterPerson"   />

                </div>

                <div class="form-group col-md-2">
                  <button *ngIf="conjoint.get('professionMatricule').value && conjoint.get('profession').value == 1" nbButton status="info" style='margin: 26px 0 0 0;' type="button" (click)="openConjoint(dialog, conjoint.get('professionMatricule').value.id)"><i class="fas fa-link"></i></button> &nbsp;
                  <button  *nbIsGranted="['delete', 'conjoint']"  nbButton status="danger" style='margin: 26px 0 0 0;' type="button" (click)="beforeDelete(i, 'conjoint', 'Suppression de conjoint', 'Cette action n\'est pas revocable')"><i class="fas fa-trash"></i></button>
                </div>

              </div>
            </div>



          </div>


        </div>


        <button *nbIsGranted="['create', 'conjoint']"  nbButton status="success" type="button" (click)="addConjointButtonClick()" [disabled]="personneForm.get('conjoints').invalid"><i class="fas fa-plus"></i></button>



      </nb-accordion-item-body>

    </nb-accordion-item>

    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'enfant']" >

      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Enfant(s)
      </nb-accordion-item-header>

      <nb-accordion-item-body>


        <div formArrayName="enfants" *ngFor="let enfant of personneForm['controls'].enfants['controls']; let i=index" >
          <div [formGroupName]="i" >
            <hr *ngIf="i>0" />

            <div class="form-row">

              <div class="col-md-10">

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label [attr.for]="'nom_enfant_' +i">Nom</label>
                    <input type="hidden" class="form-control" formControlName="id" />
                    <input type="text" class="form-control" placeholder="Nom" formControlName="nom" [id]="'enfant_nom_' +i" [ngClass]="{'is-invalid': enfant.get('nom').invalid && enfant.get('nom').touched}" (blur)="logValidationErrors()" />
                  </div>

                  <div class="form-group col-md-3">
                    <label [attr.for]="'enfant_prenom_' +i">Prénom</label>
                    <input type="text" class="form-control" placeholder="Prénom" formControlName="prenom" [id]="'enfant_prenom_' +i" [ngClass]="{'is-invalid': enfant.get('prenom').invalid && enfant.get('prenom').touched}"  (blur)="logValidationErrors()"/>
                  </div>

                  <div class="form-group col-md-3">
                    <label [attr.for]="'enfant_prenom_deux_' +i">Deuxième prénom</label>
                    <input type="text" class="form-control" placeholder="Deuxième prénom" formControlName="prenomDeux" [id]="'enfant_prenom_deux_' +i" [ngClass]="{'is-invalid': enfant.get('prenomDeux').invalid && enfant.get('prenomDeux').touched}" (blur)="logValidationErrors()"  />
                  </div>

                  <div class="form-group col-md-3">
                    <label class="control-label" [attr.for]="'dateNaissanceEnfant' +i">Date de naissance</label>
                    <div class="input-group">
                      <input class="form-control"  placeholder="dd/mm/yyyy" container="body"   formControlName="dateNaissance"
                             [id]="'dateNaissanceEnfant' +i" placement="top"
                             ngbDatepicker #denfant="ngbDatepicker"
                             (ngModelChange)="onChildDateChange($event, i)"
                             [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true" [ngClass]="{'is-invalid': enfant.get('dateNaissance').invalid && enfant.get('dateNaissance').touched}" (blur)="logValidationErrors()"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="denfant.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="form-row">

                  <div class="form-group col-md-3">

                    <label [attr.for]="'acharge' +i">À charge</label>

                    <select class="form-control" formControlName="acharge" id="'acharge' +i" [ngClass]="{'is-invalid': enfant.get('acharge').invalid && enfant.get('acharge').touched}" (blur)="logValidationErrors()" >
                      <option *ngFor="let item of acharges" [ngValue]="item.value ">
                        {{ item.viewValue }}
                      </option>
                    </select>

                  </div>


                  <div class="form-group col-md-3">
                    <label [attr.for]="'enfantEtablissement' +i">Scolarisé</label>
                    <select class="form-control" formControlName="enfantEtablissement" id="'enfantEtablissement' +i" (change)="selectEtablissementHandler($event, i)" [ngClass]="{'is-invalid': enfant.get('enfantEtablissement').invalid && enfant.get('enfantEtablissement').touched}" (blur)="logValidationErrors()">
                      <option *ngFor="let item of (etablissements | async)" [ngValue]="item.id ">
                        {{ item.description }}
                      </option>
                    </select>

                  </div>


                  <div class="form-group col-md-3">
                    <label [attr.for]="'scolarise' +i">INE</label>
                    <input type="text" class="form-control" placeholder="INE" formControlName="scolarise" [id]="'scolarise' +i" [ngClass]="{'is-invalid': enfant.get('scolarise').invalid && enfant.get('scolarise').touched}" (blur)="logValidationErrors()" />
                  </div>

                  <div class="form-group col-md-3">
                    <label [attr.for]="'responsableDeuxModel' +i">Responsable deux</label>


                    <input type="text" class="form-control" formControlName="responsableDeux" [id]="'responsableDeux' +i" [ngClass]="{'is-invalid': enfant.get('responsableDeux').invalid && enfant.get('responsableDeux').touched}"
                           [ngbTypeahead]="personSearch" [editable]='false' (selectItem)="selectedResponsableDeuxItem($event, i)"
                           placeholder="Deuxième Responsable" container="body" [resultFormatter]="formatterPerson" [inputFormatter]="formatterPerson" (blur)="logValidationErrors()" />

                  </div>

                </div>

              </div>

              <div class="col-md-2" style="text-align: center">
                <button *ngIf="enfant.get('responsableDeux').value" nbButton status="info" style='margin: 26px 0 0 0;' type="button" (click)="openConjoint(dialog, enfant.get('responsableDeux').value.id)"><i class="fas fa-child"></i></button> &nbsp;

                <button *nbIsGranted="['delete', 'enfant']"  nbButton status="danger" style='margin: 26px 0 0 0;' type="button" (click)="beforeDelete(i, 'enfant', 'Suppression de enfant', 'Cette action n\'est pas revocable')"><i class="fas fa-trash"></i></button>
              </div>
            </div>

          </div>

        </div>
        <button *nbIsGranted="['create', 'enfant']"  nbButton status="success" type="button" (click)="addEnfantButtonClick()" [disabled]="personneForm.get('enfants').invalid"><i class="fas fa-plus"></i></button>


      </nb-accordion-item-body>

    </nb-accordion-item>



    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'adresse']" >

      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Adresse(s)
      </nb-accordion-item-header>

      <nb-accordion-item-body>

        <div formArrayName="adresses"  *ngFor="let adresse of personneForm['controls'].adresses['controls']; let i=index" >

          <div [formGroupName]="i" >
            <hr *ngIf="i>0" />
            <div class="form-row">

              <div class="form-group col-md-3">
                <label [attr.for]="'rue' +i">Adresse</label>
                <input type="hidden" class="form-control" formControlName="id" />

                <input type="text" class="form-control" placeholder="Adresse " formControlName="rue" [id]="'rue' +i" [ngClass]="{'is-invalid': adresse.get('rue').invalid && adresse.get('rue').touched }" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-1">
                <label [attr.for]="'codePostal' +i">Code postal</label>
                <input type="text" maxlength="5" class="form-control" placeholder="Code postal " formControlName="codePostal" [id]="'codePostal' +i" [ngClass]="{'is-invalid': (adresse.get('codePostal').invalid && adresse.get('codePostal').touched)}" (blur)="logValidationErrors()"  />
              </div>
              <div class="form-group col-md-2">
                <label [attr.for]="'ville' +i">Ville</label>
                <input type="text" class="form-control" placeholder="Ville" formControlName="ville" [id]="'ville' +i" [ngClass]="{'is-invalid': adresse.get('ville').invalid && adresse.get('ville').touched}" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-1">
                <label [attr.for]="'country' +i">Pays</label>

                <input type="text" class="form-control" formControlName="country" id="country" [id]="'pays' +i" [ngClass]="{'is-invalid': adresse.get('country').invalid && adresse.get('country').touched}" (blur)="logValidationErrors()"
                       [ngbTypeahead]="countrySearch" [editable]='false' (selectItem)="selectedAdresseItem($event, i)"
                       placeholder="Pays" container="body" [resultTemplate]="rt" [inputFormatter]="formatter"   />

              </div>
              <div class="form-group col-md-2">
                <label [attr.for]="'telephone' +i">Téléphone</label>
                <input type="text" class="form-control" placeholder="Téléphone" formControlName="telephone" maxlength="10" [id]="'telephone' +i" [ngClass]="{'is-invalid': adresse.get('telephone').invalid && adresse.get('telephone').touched}" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-2">
                <label [attr.for]="'gsm' +i">GSM</label>
                <input type="text" class="form-control" placeholder="Téléphone" formControlName="gsm" maxlength="10" [id]="'gsm' +i" [ngClass]="{'is-invalid': adresse.get('gsm').invalid && adresse.get('gsm').touched}" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-1">
                <br />
                <button *nbIsGranted="['delete', 'adresse']"  nbButton status="danger" style='margin: 7px 0 0 0;' type="button" (click)="beforeDelete(i, 'adresse', 'Suppression adresse', 'Cette action n\'est pas revocable')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </div>

        </div>
        <button *nbIsGranted="['create', 'adresse']"  nbButton status="success" type="button" (click)="addAdresseButtonClick()" [disabled]="personneForm.get('adresses').invalid"><i class="fas fa-plus"></i></button>



      </nb-accordion-item-body>

    </nb-accordion-item>

    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'comptebancaire']" >

      <nb-accordion-item-header class="nb-accordion-item-header-override" >
        Compte(s) bancaire(s)
      </nb-accordion-item-header>

      <nb-accordion-item-body>

        <div formArrayName="compteBancaires" *ngFor="let compteBancaire of personneForm['controls'].compteBancaires['controls']; let i=index" >

          <div [formGroupName]="i" >
            <hr *ngIf="i>0" />
            <div class="form-row">
              <div class="form-group col-md-2">
                <label [attr.for]="'country' +i">Pays</label>
                <input type="hidden" class="form-control" formControlName="id" />
                <input type="text" class="form-control" formControlName="country" [id]="'country' +i" [ngClass]="{'is-invalid': compteBancaire.get('country').invalid && compteBancaire.get('country').touched}" (blur)="logValidationErrors()"
                       [ngbTypeahead]="countrySearch" [editable]='false' (selectItem)="selectedCompteBancaireItem($event, i)"
                       placeholder="Pays" [resultTemplate]="rt" [inputFormatter]="formatter"   />
              </div>
              <div class="form-group col-md-3">
                <label [attr.for]="'numeroCompte' +i">Numéro de compte</label>
                <input type="text" class="form-control" placeholder="Numéro de compte" formControlName="numeroCompte" [id]="'numeroCompte' +i" [ngClass]="{'is-invalid': compteBancaire.get('numeroCompte').invalid && compteBancaire.get('numeroCompte').touched}" (blur)="logValidationErrors()"/>
              </div>
              <div class="form-group col-md-2">
                <label [attr.for]="'libelleBanque_' +i">Banque</label>
                <input type="text" class="form-control" placeholder="Banque" formControlName="libelleBanque" [id]="'libelleBanque_' +i" [ngClass]="{'is-invalid': compteBancaire.get('libelleBanque').invalid && compteBancaire.get('libelleBanque').touched}" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-2">
                <label [attr.for]="'adresseBanque_' +i">Adresse Banque</label>
                <input type="text" class="form-control" placeholder="Adresse Banque" formControlName="adresseBanque" [id]="'adresseBanque_' +i" [ngClass]="{'is-invalid': compteBancaire.get('adresseBanque').invalid && compteBancaire.get('adresseBanque').touched}" (blur)="logValidationErrors()" />
              </div>
              <div class="form-group col-md-2">
                <label class="control-label" [attr.for]="'dateRib_' +i">Date R.I.B</label>
                <div class="input-group">
                  <input class="form-control"  placeholder="dd/mm/yyyy" container="body"   formControlName="dateRib"
                         [id]="'dateRib_' +i" placement="top"
                         ngbDatepicker #d3="ngbDatepicker"
                         (ngModelChange)="onCompteBancaireDateChange($event, i)"
                         [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true" [ngClass]="{'is-invalid': compteBancaire.get('dateRib').invalid && compteBancaire.get('dateRib').touched}" (blur)="logValidationErrors()"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-1">
                <br />
                <button *nbIsGranted="['delete', 'comptebancaire']" nbButton status="danger" style='margin: 7px 0 0 0;' type="button" (click)="beforeDelete(i, 'compteBancaire', 'Suppression de compte Bancaire', 'Cette action n\'est pas revocable')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </div>

        </div>

        <button *nbIsGranted="['create', 'comptebancaire']"  nbButton status="success" type="button" (click)="addCompteBancaireButtonClick()" [disabled]="personneForm.get('compteBancaires').invalid"><i class="fas fa-plus"></i></button>



      </nb-accordion-item-body>

    </nb-accordion-item>

    <nb-accordion-item [expanded]="true" *nbIsGranted="['view', 'diplome']" >

      <nb-accordion-item-header class="nb-accordion-item-header-override">
        Diplome(s)
      </nb-accordion-item-header>

      <nb-accordion-item-body>

        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="typeNiveau">Niveau</label>

            <select class="form-control" formControlName="typeNiveau" id="typeNiveau" [ngClass]="{'is-invalid': personneForm.get('typeNiveau').invalid && personneForm.get('typeNiveau').touched}" (blur)="logValidationErrors()" >
              <option *ngFor="let item of (typeNiveaux | async)" [ngValue]="item.id ">
                {{ item.description }}
              </option>
            </select>


          </div>
        </div>

        <div formArrayName="diplomes" *ngFor="let diplome of  personneForm['controls'].diplomes['controls'] ; let i=index" >

          <div [formGroupName]="i" >
            <hr *ngIf="i>0" />
            <div class="form-row">
              <div class="form-group col-md-1">
                <label [attr.for]="'annee_' +i">Année</label>
                <input type="hidden" class="form-control" formControlName="id" />
                <select class="form-control" formControlName="annee" id="'annee_' +i" [ngClass]="{'is-invalid': diplome.get('annee').invalid && diplome.get('annee').touched}" (blur)="logValidationErrors()" >
                  <option *ngFor="let item of years" [ngValue]="item.toString()">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="'niveau' +i"  [attr.for]="'niveau' +i">Niveau</label>

                <select class="form-control" formControlName="niveau" id="'niveau' +i" [ngClass]="{'is-invalid': diplome.get('niveau').invalid && diplome.get('niveau').touched}" (blur)="logValidationErrors()">
                  <option *ngFor="let item of (niveaux | async)" [ngValue]="item.id ">
                    {{ item.description }}
                  </option>
                </select>

              </div>
              <div class="form-group col-md-7">
                <label [attr.for]="'libelle' +i">Diplome</label>
                <input type="text" class="form-control" placeholder="Diplome" formControlName="libelle" [id]="'libelle' +i" [ngClass]="{'is-invalid': diplome.get('libelle').invalid && diplome.get('libelle').touched}" (blur)="logValidationErrors()"  />

              </div>
              <div  class="form-group col-md-1">
                <br />
                <button *nbIsGranted="['delete', 'diplome']" nbButton status="danger" style='margin: 7px 0 0 0;' type="button" (click)="beforeDelete(i, 'diplome', 'Suppression de diplôme', 'Cette action n\'est pas revocable')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </div>
        </div>

        <button *nbIsGranted="['create', 'diplome']" nbButton status="success" type="button" (click)="addDiplomeButtonClick()" [disabled]="personneForm.get('diplomes').invalid"><i class="fas fa-plus"></i></button>


        <div class="form-row" style="margin-top: 12px" *nbIsGranted="['create', 'personnel']">
          <div class="form-group col-md-12">
            <button nbButton status="primary" type="submit" [disabled]="personneForm.invalid">Enregistrer</button>
          </div>
        </div>



      </nb-accordion-item-body>

    </nb-accordion-item>


  </nb-accordion>

</form>

