<nb-card *ngIf="absences.length > 0"  status="primary" style="margin-top: 10px" >
    <nb-card-header>
        Liste d'absences de l'agent
    </nb-card-header>
    <nb-card-body>
        <div class="form-group col-md-12">
            <table class="table table-hover table-bordered display responsive" width="100%">
                <thead>
                <tr>
                    <th class="all">Établissement</th>
                    <th class="all">Type</th>
                    <th class="all">Motif</th>
                    <th class="all">Date début</th>
                    <th class="all">Date fin</th>
                    <th class="all">Date de saisie</th>
                    <th class="all">Durée</th>
                    <th class="all">À rattraper</th>
                    <th class="all">Traiter</th>
                    <th class="all">Observation</th>
                    <th *nbIsGranted="['download', 'evaluation']" class="all">Document</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of absences" >
                    <td>{{ row.etablissement }}</td>
                    <td>{{ row.absenceType }}</td>
                    <td>{{ row.absenceMotif }}</td>
                    <td>
                        <span style="display: none">{{ row.dateDebut | amDateFormat:'YYYY-MM-DD' }}</span>
                        {{ row.dateDebut | amDateFormat:'DD/MM/YYYY' }}
                    </td>
                    <td>
                        <span style="display: none">{{ row.dateFin | amDateFormat:'YYYY-MM-DD' }}</span>
                        {{ row.dateFin | amDateFormat:'DD/MM/YYYY' }}
                    </td>
                    <td>
                        <span style="display: none">{{ row.creationDate | amDateFormat:'YYYY-MM-DD' }}</span>
                        {{ row.creationDate | amDateFormat:'DD/MM/YYYY' }}
                    </td>
                    <td>{{ row.duree }}</td>
                    <td>
              <span class="btn" *ngIf="row.rattraper" [ngClass]="'btn-outline-success'">
                OUI
              </span>
                        <span class="btn" *ngIf="!row.rattraper" [ngClass]="'btn-outline-danger'">
                NON
              </span>
                    </td>
                    <td>
              <span class="btn" *ngIf="row.traiter" [ngClass]="'btn-outline-success'">
                OUI
              </span>
                        <span class="btn" *ngIf="!row.traiter" [ngClass]="'btn-outline-danger'">
                NON
              </span>
                    </td>
                    <td>{{ row.observation }}</td>
                    <td>
                        <div class="form-inline">
                            <div class="btn-group" btnRadioGroup>
                                <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" (click)="preview(row.id, row.agentId)" title="Apercu"> <i class="fas fa-eye"></i></label>
                            </div>
                        </div>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    </nb-card-body>
</nb-card>