import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentModel} from "../../../../lyautey/model/document.model";
import {ViewPdfComponent} from "../documents/view-pdf/view-pdf.component";
import {AbsenceService} from "../../../../lyautey/services/absence.service";
import {DocumentService} from "../../../../lyautey/services/document.service";
import {NbDialogService} from "@nebular/theme";

@Component({
  selector: 'ngx-absence',
  templateUrl: './absence.component.html',
  styleUrls: ['./absence.component.scss']
})
export class AbsenceComponent implements OnInit {

  dataTable: any;
  dataTableSettings: any = { fixedColumns: true};
  result = [];
  settings = {};
  absences = [];
  private blob: Blob;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private service: AbsenceService,
      private documentsService: DocumentService,
      private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(params => {
      const id: number = Number(params.get('id'));
      if (id) {
        this.service.getAllByAgentId(id)
            .subscribe((result: any) => {
                  this.absences = result;
                },
            );
      }
    });
  }

  preview(absenceId: number, personneId: number) {
    this.documentsService.getPreview(personneId, absenceId, 18).subscribe((document: DocumentModel) => {
      this.documentsService.getByUrl(document._links.typeDocument.href).subscribe(value => {
        if (value !== undefined) {
          document['typeDocument'] = value['description'];
          this.documentsService.downloadFile(document.id).subscribe(data => {
            this.blob = new Blob([data], {type: 'application/octet-stream'});
            const downloadURL = window.URL.createObjectURL(data);
            const extension: string = document.file.split('.').pop();
            const viewer = extension === 'pdf' ? 'pdf' : 'office';
            this.dialogService.open(ViewPdfComponent, {
              closeOnEsc: true,
              autoFocus: true,
              hasScroll: true,
              hasBackdrop: true,
              context: {
                document: document,
                fileSrc: downloadURL,
                viewer: viewer,
              },
            });
          });
        }
      });
    });
  }
}
