import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-absence-alertes',
  templateUrl: './absence-alertes.component.html',
  styleUrls: ['./absence-alertes.component.scss']
})
export class AbsenceAlertesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
