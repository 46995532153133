import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {ApplicationService} from './application.service';
import {DbDate} from '../shared/dbDate';
import {FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {AbsenceModel} from '../model/absence.model';
import {catchError, map, tap} from 'rxjs/operators';
import {PersonneModel} from '../model/personnel.model';
import {AbsenceMotifModel} from '../model/absenceMotif.model';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

const httpBlobOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class AbsenceService extends ApplicationService {

    model: any = {};

    constructor(httpClient: HttpClient, private dbDate: DbDate) {
        super(httpClient, 'absences', 'absence');
    }

    dateDebut: string;
    dateFin: string;
    duree: number;
    absenceMotif: string;
    absenceType: string;

    setAbsenceData(group: FormGroup): void {
        this.model = {
            'duree': group.get('duree').value,
            'observation': group.get('observation').value,
            'rattraper': group.get('rattraper').value,
            'traiter': group.get('traiter').value,
            'absenceMotif': group.get('absenceMotif').value,
        };
        if (group.get('dateDebut').value !== undefined &&
            group.get('dateDebut').value !== null
        ) {
            this.model.dateDebut = this.dbDate.format(group.get('dateDebut').value);
        }
        if (group.get('dateFin').value !== undefined &&
            group.get('dateFin').value !== null
        ) {
            this.model.dateFin = this.dbDate.format(group.get('dateFin').value);
        }
        if (Number(group.get('personne').value) > 0) {
            const personneId = Number(group.get('personne').value);
            this.model.personne = environment.serverUrl + `/personnes/${personneId}`;
        }
        if (Number(group.get('absenceType').value) > 0) {
            const absenceTypeId = Number(group.get('absenceType').value);
            this.model.absenceType = environment.serverUrl + `/absenceTypes/${absenceTypeId}`;
        }
        if (Number(group.get('etablissement').value) > 0) {
            const etablissementId = Number(group.get('etablissement').value);
            this.model.etablissement = environment.serverUrl + `/etablissements/${etablissementId}`;
        }
    }

    setMultipleAbsenceData(group: FormGroup): void {
        this.model = {
            'duree': group.get('duree').value,
            'observation': group.get('observation').value,
            'rattraper': group.get('rattraper').value,
            'traiter': group.get('traiter').value,
            'absenceMotif': group.get('absenceMotif').value,
        };
        if (group.get('dateDebut').value !== undefined &&
            group.get('dateDebut').value !== null
        ) {
            this.model.dateDebut = this.dbDate.format(group.get('dateDebut').value);
        }
        if (group.get('dateFin').value !== undefined &&
            group.get('dateFin').value !== null
        ) {
            this.model.dateFin = this.dbDate.format(group.get('dateFin').value);
        }
        if (Number(group.get('personne').value) > 0) {
            const personneId = Number(group.get('personne').value);
            this.model.personne = environment.serverUrl + `/personnes/${personneId}`;
        }
        if (Number(group.get('absenceType').value) > 0) {
            const absenceTypeId = Number(group.get('absenceType').value);
            this.model.absenceType = environment.serverUrl + `/absenceTypes/${absenceTypeId}`;
        }
        if (Number(group.get('etablissement').value) > 0) {
            const etablissementId = Number(group.get('etablissement').value);
            this.model.etablissement = environment.serverUrl + `/etablissements/${etablissementId}`;
        }
    }

    addAbsence(group: FormGroup): Observable<AbsenceModel> {
        this.setAbsenceData(group);
        return this.httpClient.post<AbsenceModel>(`${environment.serverUrl}/absences`,
            this.model, httpOptions).pipe(
            tap((personne: AbsenceModel) => {
            }),
            catchError(this.handleError<AbsenceModel>(`addabsence`)),
        );
    }

    addMultipleAbsence(group: FormGroup): Observable<AbsenceModel> {

        if (group.get('personne').value) {
            const personneId = Number(group.get('personne').value);
            console.log('personneId', personneId);
            return null;
        }
        this.setMultipleAbsenceData(group);
        return this.httpClient.post<AbsenceModel>(`${environment.serverUrl}/bulk`,
            this.model, httpOptions).pipe(
            tap((personne: AbsenceModel) => {
            }),
            catchError(this.handleError<AbsenceModel>(`addabsence`)),
        );
    }

    updateAbsence(id: number, group: FormGroup): Observable<AbsenceModel> {
        this.setAbsenceData(group);
        return this.httpClient.patch<AbsenceModel>(`${environment.serverUrl}/absences/${id}`,
            this.model, httpOptions).pipe(
            tap((personne: AbsenceModel) => {
            }),
            catchError(this.handleError<any>('updateabsence')),
        );
    }

    getPersonnes(id: number): Observable<PersonneModel> {
        const url = environment.serverUrl + `/absences/${id}/personnes`;
        return this.httpClient.get<PersonneModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<PersonneModel>(`get EnfantModel id=${id}`)),
        );
    }

    getAllByAgentId(agentId): any {
        // @ts-ignore
        return this.httpClient.get(
            `${environment.serverBaseUrl}${this.controller}/listing-agent/${agentId}`,
            {}).pipe(
            map(response => {
                return response;
            }),
            catchError(this.handleError('getAllByAgentId', [])),
        );

    }

    getEtablissements(): any {
        return this.httpClient.post(`${environment.serverBaseUrl}absence/etablissements`,
            {}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getAgents(etablissementId: number): any {
        return this.httpClient.post(`${environment.serverBaseUrl}absence/agents`,
            {'etablissementId': etablissementId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getByType(absenceTypeId: number): Observable<AbsenceMotifModel[]> {
        // @ts-ignore
        return this.httpClient.get(
            environment.serverBaseUrl +
            `absence/getMotifsByAbsenceType/${absenceTypeId}`,
            {}).pipe(
            map(response => {
                return response;
            }),
            catchError(this.handleError('getByAbsenceType', [])),
        );
    }

    export(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}absence/export`,
            data, httpBlobOptions).pipe(
            tap(_ => console.log(`export`)),
            catchError(this.handleError<any>('export')),
        );
    }

    exportCumulAbsence(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}absence/export-cumul-absence`,
            data, httpBlobOptions).pipe(
            tap(_ => console.log(`export`)),
            catchError(this.handleError<any>('export')),
        );
    }


    reportListing(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/report-listing`,
            data, this.httpOptions).pipe(
            tap(_ => console.log(`reportListing`)),
            catchError(this.handleError<any>('reportListing')),
        );
    }

    alertsListing(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/absence-alerts`,
            {}, this.httpOptions).pipe(
            tap(_ => console.log(`alertsListing`)),
            catchError(this.handleError<any>('alertsListing')),
        );
    }

    advancedListing(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listing`,
            data, this.httpOptions).pipe(
            tap(_ => console.log(`advancedListing`)),
            catchError(this.handleError<any>('advancedListing')),
        );
    }

    updateDocument(id: number, documentId: number): Observable<AbsenceModel> {
        return new Observable((observer) => {
            const absence = {
                id: id,
                document: environment.serverUrl + `/documents/${documentId}`,
            };

            this.update(id, absence)
                .subscribe((model: any) => {
                        observer.next(model);
                        observer.complete();
                    }, (err: any) => {
                        observer.error(err);
                    },
                );
        });
    }

    addBulkAbsences(formGroup: FormGroup, file: File): Observable<any> {
        const absenceRequest = {
            duree: formGroup.get('duree')?.value,
            observation: formGroup.get('observation')?.value,
            rattraper: formGroup.get('rattraper')?.value,
            traiter: formGroup.get('traiter')?.value,
            absenceMotif: formGroup.get('absenceMotif')?.value,
            absenceType: formGroup.get('absenceType')?.value,
            dateDebut: this.dbDate.format(formGroup.get('dateDebut')?.value),
            dateFin: this.dbDate.format(formGroup.get('dateFin')?.value),
            personnes: formGroup.get('personne')?.value.map((person: any) => ({
                personneId: person.id,
                nomComplet: `${person.civilite} ${person.nom} ${person.prenom}`,
                etablissementId: person.etablissementId,
            })),
        };

        // Create a FormData object to hold the file and JSON payload
        const formData = new FormData();
        formData.append('file', file);
        formData.append('absenceRequest', new Blob([JSON.stringify(absenceRequest)], { type: 'application/json' }));

        const headers = new HttpHeaders(); // Remove the 'Content-Type' setting
        return this.httpClient.post(`${environment.serverBaseUrl}absence/bulk`, formData, {
            reportProgress: true,
            responseType: 'json',
        });

    }


}
